<template>
  <div>
    <div class="shadow"></div>
    <div class="fixed-full">
      <div class="container h-100">
        <div class="row align-items-end h-100">
          <div class="col-12">
            <router-view v-slot="slotProps">
              <transition :name="transitionName" mode="out-in">
                <component :is="slotProps.Component"></component>
              </transition>
            </router-view>
          </div>


          <div class="col-12">
            <div class="row align-items-end">
              <div class="col">
                <transition name="fade-slow" mode="out-in">
                  <h1 class="h2 font-weight-bold" :key="$route.name">
                    {{ routes[$route.name].titre }}
                  </h1>
                </transition>
              </div>
            </div>
            <div class="row mb-4 py-3 align-items-center">
              <div class="col-auto">
                <router-link :to="{name: routes[$route.name].links.prev}" class="btn btn-outline-light">
                  <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="mr-md-3">
                  <transition name="fade-slow" mode="out-in">
                    <span :key="$route.name" class="d-none d-md-inline-block">{{ routes[routes[$route.name].links.prev].titre }}</span>
                  </transition>
                </router-link>
              </div>
              <div class="col-auto ml-auto">
                <router-link :to="{name: routes[$route.name].links.next}" class="btn btn-outline-light">
                  <transition name="fade-slow" mode="out-in">
                    <span :key="$route.name" class="d-none d-md-inline-block">{{ routes[routes[$route.name].links.next].titre }}</span>
                  </transition>
                  <img src="/images/icons/arrow-right-white.svg" alt="Flèche droite" class="ml-md-3">
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ArriereScene',
	components: {
	},
	data: () => ({
    routes: {
      "ArriereSceneArtisans": {
        titre: "En tête-à-tête avec les artisans",
        id: 1,
        links: {
          prev: "ArriereSceneCollaborateurs",
          next: "ArriereScenePhotos",
        },
      },
      "ArriereScenePhotos": {
        titre: "Photos",
        id: 2,
        links: {
          prev: "ArriereSceneArtisans",
          next: "ArriereSceneCollaborateurs",
        },
      },
      "ArriereSceneCollaborateurs": {
        titre: "Collaborateurs",
        id: 3,
        links: {
          prev: "ArriereScenePhotos",
          next: "ArriereSceneArtisans",
        },
      }
    },
    transitionName: "slide-left"
  }),
  mounted() {
    console.log(this.$refs)
  },
  watch: {
    '$route' (to, from) {
      if(to.name.indexOf("ArriereScene") >= 0 && to.name.indexOf("SingleArriereSceneArtisans") != 0) {
        let fromId = this.routes[from.name].id
        let toId = this.routes[to.name].id

        console.log(fromId - toId)

        if(fromId - toId < -1) {
          this.transitionName = "slide-right"
        } else if(fromId - toId < 0 || fromId - toId > 1) {
          this.transitionName = "slide-left"
        } else {
          this.transitionName = "slide-right"
        }
      }
    },
  }
}
</script>
