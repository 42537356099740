<template>
  <div class="py-5 mt-5">
    <div class="row">
      <div class="col-12">
        <h2 class="h1 mt-4 mb-4 mb-md-5">Au-delà des chiffres</h2>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-md-7 col-lg-6">
        <p class="h5 mb-4 font-serif font-weight-bold">Une personne atteinte, plusieurs vies touchées</p>
        <p>Au Québec, plus de 153 000 personnes sont atteintes de la maladie d’Alzheimer. Ce chiffre pourrait grimper à 260 000 d’ici 15 ans, selon la Fédération québécoise des Sociétés Alzheimer.</p>
        <p>Les membres de leur entourage, dont plusieurs personnes proches aidantes, sont aussi touchés par la maladie. Ils voient leurs vies se transformer au rythme de celles de leurs proches atteints. La Fédération québécoise des Sociétés Alzheimer estime que pour chaque personne atteinte de la maladie d’Alzheimer, un à trois proches aidants s’investissent en temps, en énergie et en soins auprès de cette personne.</p>
      </div>
      <div class="col-12 col-md-5 col-lg-6 text-md-right my-4">
        <router-link :to="{name: 'AuDelaDesChiffresRessources'}" class="btn btn-outline-light">
          Découvrir nos ressources
          <img src="/images/icons/arrow-right-white.svg" alt="Flèche droite" class="ml-3" width=28.91 height="19.27">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuDelaDesChiffresHome',
}
</script>
