<template>
  <div class="container">
    <img src="/images/IMG_blured_outils.png" alt="" class="image-outils position-fixed img-fluid">
    <div class="image-outils position-fixed h-100 w-50"></div>
    <div class="shadow"></div>
    <div class="content w-100 position-relative">
      <router-view v-slot="slotProps">
        <transition :name="transitionName" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutilsAnimation',
  data: () => ({
    transitionName: "slide-left"
  }),
  watch: {
    '$route' (to, from) {
      // console.log('outils transition');
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      // console.log(to,toDepth,fromDepth);

      this.transitionName = 'slide-right';

      if( toDepth > fromDepth ||
          to.path.indexOf('demande') > 0 ||
          to.path.indexOf('objectifs') > 0) {
        this.transitionName = 'slide-left';
      }
    }
  }
}
</script>
