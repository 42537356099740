<template>
  <div>
    <div @mouseleave="showControls = false" class="video-container pt-5 pt-md-0" :class="{'playing' : playing}" @mousemove="showControlsFunction" @click="showControlsFunction">

      <transition :name="videoTransition">
        <div :key="$route.params.id" class="video-wrapper d-none d-md-block">
          <video ref="videoPlayer" class="w-100 h-100 full-video" :class="$route.params.id" :poster="chapitres[$route.params.id].image_poster">
            <!-- <source src="/media/cc0-videos/flower.webm" type="video/webm"> -->
            <source :src="chapitres[$route.params.id].video" type="video/mp4">
            <track label="Aucun" kind="subtitles" srclang="" src="">
            <track v-for="(subs, key) in chapitres[$route.params.id].subs" :key="key" :label="subs.label" kind="subtitles" :srclang="key" :src="subs.url">
            Sorry, your browser doesn't support embedded videos.
          </video>

          <transition name="fade-slow">
            <div class="video-overlay position-absolute w-100 h-100" v-if="!playing || showControls"></div>
          </transition>

          <svg class="play-btn" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" @click="play">
            <g transform="translate(-639 -299.333)">
              <g stroke="#fff" stroke-width="1.5px" class="bg-play" transform="translate(639 299.333)">
                <circle stroke="none" cx="30" cy="30" r="30" />
                <circle fill="none" cx="30" cy="30" r="29.25" />
              </g>
              <path fill="#fff" d="M9.274,0l9.274,19.209H0Z" transform="translate(681.062 319.921) rotate(90)" />
            </g>
          </svg>
        </div>
      </transition>


      <div class="container full-height-container">
        <div class="row align-items-end h-100">

          <transition :name="videoTransition" mode="out-in">
            <div :key="$route.params.id">
              <video controls class="w-100 mt-5 mb-4 d-block d-md-none mobile-video" :class="$route.params.id" :poster="chapitres[$route.params.id].image_poster">
                <source :src="chapitres[$route.params.id].video" type="video/mp4">
                <track v-for="(subs, key) in chapitres[$route.params.id].subs" :key="key" :label="subs.label" kind="subtitles" :srclang="key" :src="subs.url">
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </transition>

          <transition name="slide-from-left">
            <router-link :to="{name:'SingleChapitre',params:{id:chapitres[$route.params.id].links.next,autoplay:true}}" v-if="playing && (duration - currentTime < 70) && currentTime > 10" class="d-none d-md-flex row bg-dark position-absolute next-chapter shadow-sm">
                <div class="col-auto bg-cover px-4 d-flex align-items-center" :style="'background-image:url('+chapitres[chapitres[$route.params.id].links.next].image_poster+')'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 60">
                    <g transform="translate(-639 -299.333)">
                      <g stroke="#fff" stroke-width="1.5px" fill="none" transform="translate(639 299.333)">
                        <circle stroke="none" cx="30" cy="30" r="30" />
                        <circle fill="rgba(255,255,255,0.1)" cx="30" cy="30" r="29.25" />
                      </g>
                      <path fill="#fff" d="M9.274,0l9.274,19.209H0Z" transform="translate(681.062 319.921) rotate(90)" />
                    </g>
                  </svg>
                </div>
                <div class="col">
                  <div class="px-2 py-3">
                    <p class="mb-0 small font-serif"><em>– Chapitre suivant</em></p>
                    <p class="mb-0">{{ chapitres[chapitres[$route.params.id].links.next].titre }}</p>
                  </div>
                </div>
            </router-link>
          </transition>

          <transition name="slide-down" mode="out-in">
            <div class="col-12 pb-4 mb-2" v-if="playing && showControls">
            <!-- <div class="col-12 pb-4 pt-3 mb-2"> -->
              <div class="row">
                <div class="col-auto ml-auto">
                  <p class="mb-0">{{ format(currentTime) }} - {{ format(duration) }}</p>
                </div>
              </div>
              <div class="video-progress my-3" @mousedown="seek($event,true)" @mouseup="clickToSeek = false" @mousemove="seek" @mouseleave="clickToSeek = false" @touchmove="seek($event,true)">
                <div class="current-time bg-white" :style="'width:' + position + '%'"></div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-auto">
                  <a href="#" @click.prevent="play" class="pr-3">
                    <img src="/images/icons/pause.svg" alt="">
                  </a>
                </div>
                <div class="col-auto ml-auto">
                  <a href="#" @click.prevent="fullscreen" class="px-2">
                    <img src="/images/icons/fullscreen.svg" alt="">
                  </a>
                </div>
                <div class="col-auto">
                  <div class="position-relative">
                    <transition name="fade">
                      <div class="bg-white position-absolute" v-show="showSubs" style="bottom:calc(100% + 40px);right:0;width:200px">
                        <div class="border-dark border-bottom">
                          <a href="#" @click="toggleSubsMenu" class="row p-3 align-items-center">
                            <div class="col-1">
                              <img src="/images/icons/chevron-left.svg" alt="" width="6">
                            </div>
                            <div class="col font-weight-bold text-uppercase text-dark">Sous-titres</div>
                          </a>
                        </div>
                        <div class="my-2">
                          <a href="#" v-for="(sub, key) in chapitres[$route.params.id].subs" :key="key" @click="subtitles(key)" class="row px-3 py-2">
                            <div class="col-1">
                              <img src="/images/icons/check.svg" alt="" v-show="subs == key" width="10">
                            </div>
                            <div class="col text-dark">{{ sub.label }}</div>
                          </a>
                        </div>
                      </div>
                    </transition>
                    <a href="#" @click.prevent="toggleSubsMenu">
                      <img src="/images/icons/soustitres.svg" alt="" v-show="!showSubs">
                      <img src="/images/icons/soustitres_white.svg" alt="" v-show="showSubs">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 caption" v-else-if="!playing">
              <div class="row align-items-end">
                <div class="col-12 col-sm">
                  <p class="h4">
                    <em>— Chapitre
                      <transition name="fade-slow" mode="out-in">
                        <span :key="$route.params.id">{{ chapitres[$route.params.id].id }}</span>
                      </transition>
                      <sub> / {{ Object.keys(chapitres).length }}</sub>
                    </em>
                  </p>
                  <transition name="fade-slow" mode="out-in">
                    <h1 class="h2 font-weight-bold" :key="$route.params.id">
                      {{ chapitres[$route.params.id].titre }}
                    </h1>
                  </transition>
                </div>
                <div class="col-auto pt-3 pt-sm-0 pb-2">
                  <router-link :to="{name:'Chapitres'}" class="d-flex align-items-center">
                    <img src="/images/icons/icon_gallerie.svg" alt="Icone gallery" class="mr-2 pr-1">
                    <span class="big font-sans-serif">Tous les chapitres</span>
                  </router-link>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-12 col-md order-md-2">
                  <transition name="fade-slow" mode="out-in">
                    <p class="mb-0 my-3 my-md-0" :key="$route.params.id">
                      {{ chapitres[$route.params.id].description }}<br>
                      <router-link to="/outils-animation/" class="text-underline">Voir les outils d'animation</router-link>
                    </p>
                  </transition>
                </div>
                <div class="col col-sm-6 col-md-auto my-sm-2 my-md-4 py-3 order-md-1">
                  <router-link :to="{name:'SingleChapitre',params:{id:chapitres[$route.params.id].links.prev}}" class="btn btn-outline-light">
                    <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="mr-0 mr-sm-3 mr-md-0 mr-lg-3">
                    <span class="d-none d-sm-inline-block d-md-none d-lg-inline-block">Chapitre {{ chapitres[chapitres[$route.params.id].links.prev].id }}</span>
                  </router-link>
                </div>
                <div class="col col-sm-6 col-md-auto my-sm-2 my-md-4 py-3 order-3 text-right">
                  <router-link :to="{name:'SingleChapitre',params:{id:chapitres[$route.params.id].links.next}}" class="btn btn-outline-light">
                    <span class="d-none d-sm-inline-block d-md-none d-lg-inline-block">Chapitre {{ chapitres[chapitres[$route.params.id].links.next].id }}</span>
                    <img src="/images/icons/arrow-right-white.svg" alt="Flèche droite" class="ml-0 ml-sm-3 ml-md-0 ml-lg-3">
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleChapitre',
	data: () => ({
    videoTransition: 'video-slide-left',
    playing: false,
    showControls: true,
    video: null,
    currentTime: 0,
    position: 0,
    duration: 0,
    timer: null,
    showSubs: false,
    subs: false,
    clickToSeek: false,

  }),
  methods: {
    reset() {
      this.playing = false;
      this.showControls = true;
      this.video = null;
      this.currentTime = 0;
      this.position = 0;
      this.duration = 0;
      this.showSubs = false;
      this.clickToSeek = false;
    },
    initVideo() {
      this.video = document.querySelector('.video-wrapper video.full-video')
      this.video.addEventListener("timeupdate", this.progressLoop);
      this.video.addEventListener('loadedmetadata', () => {
        this.currentTime = this.video.currentTime
        this.duration = this.video.duration
        this.position = this.video.currentTime * 100 / this.video.duration
      })
      // console.log(this.video)
      this.video.onended = () => {
        console.log('ended');
        this.$router.push({name:'SingleChapitre',params:{id:this.chapitres[this.$route.params.id].links.next,autoplay:true}})
      };
    },
    progressLoop() {
      this.currentTime = this.video.currentTime
      this.duration = this.video.duration
      this.position = this.video.currentTime * 100 / this.video.duration
      if(this.position == 100) {
        this.video.pause();
        this.playing = false
        this.showSubs = false;
      }
    },
    seek(e,clicked) {
      if(clicked) {
        this.clickToSeek = true
      }
      if(this.clickToSeek) {
        let posX = e.clientX || e.touches[0].clientX
        this.position = (posX - e.target.getBoundingClientRect().left) * 100 / e.target.getBoundingClientRect().width
        this.video.currentTime = this.video.duration * this.position / 100;
      }
    },
    play() {
      this.initVideo()
      this.showControlsFunction()
      console.log('on play la video',document.querySelector('.video-wrapper video.full-video').getAttribute('poster'))
      if(!this.video.paused) {
        this.video.pause();
        this.playing = false
        this.showSubs = false;
      } else {
        this.video.play();
        this.playing = true
      }
    },
    fullscreen() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
          this.video.requestFullscreen();
        } else {
          this.video.webkitRequestFullscreen();
        }
      }
    },
    showControlsFunction() {
      this.showControls = true;
      if(this.timer) {
        clearTimeout(this.timer); //cancel the previous this.timer.
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.showControls = false;
        this.showSubs = false;
      }, 2000);
    },
    subtitles(lang) {
      this.showSubs = false;
      this.subs = lang;
      for (let track of this.video.textTracks){
        if(track.language != lang){
          track.mode = "hidden"
        } else {
          track.mode = "showing"
        }
      }
    },
    toggleSubsMenu() {
      if(this.showSubs)
        this.showSubs = false;
      else
        this.showSubs = true
    },
    format(s) {
      var m = Math.floor(s / 60);
      m = (m >= 10) ? m : "0" + m;
      s = Math.floor(s % 60);
      s = (s >= 10) ? s : "0" + s;
      return m + ":" + s;
    }
  },
  created() {
    console.log('created')
  },
  watch: {
    '$route' (to, from) {
      console.log('to',to)
      if(from.name.indexOf("SingleChapitre") >= 0 && to.name.indexOf("SingleChapitre") >= 0) {
        let fromId = this.chapitres[from.params.id].id
        let toId = this.chapitres[to.params.id].id

        if(fromId - toId < -1) {
          this.videoTransition = "video-slide-right"
        } else if(fromId - toId < 0 || fromId - toId > 1) {
          this.videoTransition = "video-slide-left"
        } else {
          this.videoTransition = "video-slide-right"
        }
      }
    },
    '$route.params.id': {
      handler: function() {
        this.reset()
        if(this.$route.params.autoplay == "true") {
          setTimeout(() => {
            console.log('route watched',this.$route.params.autoplay)
            this.play()
          }, 1600);
        }
      },
      immediate: false,
      flush: "post",
    }
  }
}
</script>
