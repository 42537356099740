<template>
  <div>
    <div class="row my-5 pt-5">
      <div class="col-12 col-md-7 col-lg-8 col-xl-7 pr-5 pr-md-3">
        <h1 class="mb-5">Outils d'animation</h1>

        <swiper
          ref="outilsSwiper"
          :slides-per-view="1"
          :space-between="0"
          :speed="800"
          class="swiper-outils"
          @swiper="setFirstSwiper"
          :controller="{ control: secondSwiper }"
          effect= "fade"
          :allowTouchMove="false"
          :loop="true"
          :loopAdditionalSlides="5"
          navigation
        >
          <swiper-slide v-for="(chapitre) in chapitres" :key="chapitre.id" class="text-left">
            <div class="bg-white p-4 text-dark bg-white-before">
              <p class="text-black-50 small mb-0">Fiche d'animation 0{{ chapitre.id }}</p>
              <h3 class="mb-5">{{ chapitre.outils.titre }}</h3>
              <p class="font-weight-bold">Thèmes abordés</p>
              <div class="row">
                <div class="col-lg-6 mb-3" v-for="theme in chapitre.outils.themes" :key="theme">
                  <div class="bg-light p-4">
                    {{ theme }}
                  </div>
                </div>
              </div>
              <div v-if="chapitre.outils.extra" class="mb-3">
                <p class="mb-0 font-weight-bold">Outils de réflexion et d’animation supplémentaires</p>
                <ul>
                  <li v-for="extra in chapitre.outils.extra" :key="extra">
                    {{ extra }}
                  </li>
                </ul>
              </div>
              <router-link :to="{name: 'OutilsAnimationForm'}" class="btn btn-outline-dark mb-2 mt-3">
                Téléchargez les outils d'animation
                <img src="/images/icons/arrow-right-dark.svg" alt="Flèche droite" class="ml-3" width=28.91 height="19.27">
              </router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="d-none d-md-block col-md-5 col-lg-4 ml-auto overflow-hidden pt-5">
        <swiper
          ref="chapitresSwiper"
          :slides-per-view="1"
          :space-between="0"
          direction="vertical"
          :speed="800"
          class="position-absolute chapitre-swiper chapitre-swiper-outils"
          @swiper="setSecondSwiper"
          @slideChange="onSlideChange"
          :controller="{ control: firstSwiper }"
          :autoDestroy="true"
          :slideToClickedSlide="true"
          :loop="true"
          :loopAdditionalSlides="5"
        >
          <swiper-slide v-for="(chapitre) in chapitres" :key="chapitre.id" class="text-left">
            <p class="h6">
              <em>— Chapitre {{ chapitre.id }}</em>
            </p>
            <p class="h3 font-weight-bold">
              {{ chapitre.titre }}
            </p>
          </swiper-slide>
        </swiper>

      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Controller, EffectFade } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Controller, EffectFade]);


export default {
  name: 'Chapitres',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    firstSwiper: null,
    secondSwiper: null,
  }),
  methods: {
    setFirstSwiper(swiper) {
      this.firstSwiper = swiper;
    },
    setSecondSwiper(swiper) {
      this.secondSwiper = swiper;
    }
  },
}

</script>
