<template>
  <header class="position-fixed w-100 pointer-events-none">
    <div class="header-overlay position-absolute w-100 h-100" :style="'opacity:'+opacity"></div>
    <div class="container">
      <div class="row my-3 my-md-4 align-items-center justify-content-between">
        <div class="col-auto pointer-events-auto py-2">
          <router-link to="/">
            <img src="/images/Accueil_titre_logo.svg" alt="">
          </router-link>
        </div>
        <!-- <transition name="fade-slow">
          <div class="col-auto pointer-events-auto d-md-flex d-none" v-if="$route.matched.some(({ name }) => name != 'BaladoTestimonial')">
            <router-link :to="{name: 'BaladoTestimonial'}">
              <button class="btn btn-outline-light">
                <span>Témoignez de votre expérience</span>
              </button>
            </router-link>
          </div>
        </transition> -->
        <div class="col-auto">
          <div class="burger pointer-events-auto" :class="{'close-menu' : $parent.displayMenu}"
            @click="$parent.displayMenu ? $parent.displayMenu = false : $parent.displayMenu = true">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
	data: () => ({
    opacity:0,
  }),
  mounted() {
    document.addEventListener('scroll', () => {
      this.opacity = window.scrollY / 100;
      if(this.opacity > 1) this.opacity = 1;
    });
  }
}
</script>
