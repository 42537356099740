<template>
  <div>
    <div class="row align-items-center my-5 pt-3 pt-lg-5">
      <div class="col-12 col-lg-6">
        <h2 class="h1 mt-4 mb-4 mb-md-5">Outils d'animation</h2>
        <p>Cette section s’adresse aux personnes intervenantes et soignantes de tous les réseaux (milieu communautaire, réseau de la santé, secteur privé, etc.).</p>
        <p>C’est avec une grande joie que l’Appui pour les proches aidants vous présente cette série documentaire et ces outils d’animation pour vous accompagner dans vos activités de soutien psychosocial auprès de groupes de proches aidants.</p>
        <p>Ces outils ne visent pas à remplacer tout ce que vous avez pu concevoir et développer au sein de vos organisations en termes d’outils vidéo et de guides d’animation. Nous les avons davantage imaginés en complément à ce qui se fait déjà pour être utilisés dans leur entièreté aussi bien qu’en parties choisies selon les besoins de vos groupes. Ils se veulent simples, flexibles et facilement adaptables à la réalité de chaque milieu.</p>
        <p>Pour chaque chapitre, une fiche d’animation est proposée. Elle permet de cibler un ou des thèmes que la personne animatrice peut choisir pour ouvrir une discussion avec des personnes proches aidantes. Des objectifs d’animation de groupe, des choix de thèmes à aborder et des stratégies d’animation sont offerts. Finalement, pour certains thèmes, des outils connexes sont suggérés.</p>
      </div>
      <div class="col-12 col-lg-6 text-right my-4">
        <router-link :to="{name: 'OutilsAnimationDetails'}" class="btn btn-outline-light">
          Téléchargez les outils d'animation
          <img src="/images/icons/arrow-right-white.svg" alt="Flèche droite" class="ml-3" width=28.91 height="19.27">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutilsAnimationHome',
}
</script>
