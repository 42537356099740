<template>
  <div class="my-5 pt-3 pt-lg-4 pt-xl-5">
    <div class="row my-4 align-items-center">
      <div class="col-12 col-md-auto">
        <h2 class="h1">Au-delà des chiffres</h2>
      </div>
      <div class="col-12 col-md-auto my-4">
        <router-link :to="{name: 'AuDelaDesChiffresRessources'}" class="btn btn-outline-light">
          <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="mr-3" width="28.91" height="19.27">
          Retour
        </router-link>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-7 col-xl-6">
        <h3 class="mb-4">L’objectif premier de l’Appui, depuis sa création en 2009, est d’améliorer la qualité de vie des personnes proches aidantes.</h3>
        <p class="text-danger"><strong>Au Québec, 25% de la population de 15 ans et plus est proche aidante, soit 1,7 million de personnes (Institut de la statistique du Québec 2012).</strong></p>
        <p>Pourtant leur quotidien, leurs expériences et leur parcours sont souvent mal connus.</p>
        <p>L’Appui vise à faire connaître et reconnaître cette réalité, celle de ces femmes et de ces hommes qui font preuve d’engagement et offrent du soutien au quotidien à leur proche. Les aidantes et les aidants apportent une contribution essentielle à la société et notre engagement à mieux les soutenir collectivement est une nécessité.</p>
      </div>
      <div class="col-auto col-lg-4 col-xl-4 ml-auto my-4">
        <div class="bg-white p-4">
          <p class="text-dark mb-4">Dans une grande majorité de cas, les proches aidants prennent soin d’une personne aînée. Pour mieux connaître cette réalité, l’Appui a dressé, en 2016, un <strong>portrait détaillé des proches aidants de personnes de 65 ans et plus au Québec.</strong></p>

          <a href="https://www.lappui.org/Autour-des-proches-aidants/Qui-sont-les-proches-aidants" target="_blank" class="btn btn-block d-flex justify-content-between btn-outline-dark px-3 align-items-center">
            Découvrir ces portraits
            <img src="/images/icons/arrow-right-dark.svg" alt="Flèche droite" class="ml-3" width="28.91" height="19.27">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuDelaDesChiffresObjectifs',
}
</script>
