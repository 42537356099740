<template>
  <div>
    <transition name="fade">
      <div class="bg-overlay position-fixed w-100 h-100 d-flex align-items-center p-5" v-if="index !== null" @click.self="$emit('close')">
        <button type="button" class="btn btn-outline-light position-absolute close-gallery-btn" @click.self="$emit('close')">X</button>
        <button type="button" class="btn btn-outline-light" @click="changeIndex(-1)">
          <img src="/images/icons/arrow-left-white.svg" alt="">
        </button>
        <transition :name="transition" mode="out-in">
          <div class="mx-auto p-5 h-100" :key="tempIndex">
            <img :src="images[tempIndex].path" alt="" class="img-fluid">
            <p class="text-white mt-3">{{ images[tempIndex].name }}</p>
          </div>
        </transition>
        <button type="button" class="btn btn-outline-light" @click="changeIndex(1)">
          <img src="/images/icons/arrow-right-white.svg" alt="">
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PhotoGallery',
  props: ['images','index'],
  mounted() {
    console.log(this.images)
  },
  data: () => ({
    tempIndex: null,
    transition: 'slide-left'
  }),
  methods: {
    changeIndex(i) {
      if(this.tempIndex < this.tempIndex + i) {
        this.transition = 'slide-left'
      } else {
        this.transition = 'slide-right'
      }
      this.tempIndex = (this.tempIndex + i) % this.images.length;
      if(this.tempIndex < 0)
        this.tempIndex = this.images.length - 1
      console.log(this.tempIndex)
    }
  },
  watch: {
    'index' () {
      console.log(this.index);
      this.tempIndex = this.index;
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/_photo-gallery.scss";
</style>
