const chapitres = [{
  "les-premiers-symptomes" : {
    "id": 1,
    "links": {
      "prev": "la-fin-de-vie-de-la-personne-aidee",
      "next": "la-quete-de-services",
    },
    "titre": "Les premiers symptômes",
    "description": "Dans ce chapitre, on rencontre Édith et Michel, tous deux proches aidants de leur conjoint respectif. Ils nous présentent leur partenaire puis ils relatent comment la maladie s’est présentée dans leur vie conjugale, ses premiers symptômes, et le tumulte des démarches pour obtenir un diagnostic.",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 1 - L'Appui",
      "description": "Dans ce premier chapitre, on rencontre Édith et Michel, tous deux proches aidants de leur conjoint respectif. Découvrez leur histoire."
    },
    "image_poster": "/images/videocovers/cover-chap-1.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_1.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/subtitles/ST-ANG_1.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/subtitles/ST-FR_1.vtt",
      }
    },
    "outils": {
      "titre": "Devenir proche aidant",
      "themes": [
        "Histoire de vie et cheminement de la dyade",
        "Le doute et l’inconnu – le quotidien déstabilisé",
      ],
    }
  },
  "la-quete-de-services" : {
    "id": 2,
    "links": {
      "prev": "les-premiers-symptomes",
      "next": "le-quotidien-a-domicile",
    },
    "titre": "La quête de services",
    "description": "Édith et Michel relatent la période où la maladie de leur conjoint respectif progresse et les difficultés s’accumulent. Ils ne peuvent plus faire face seuls à cette situation et ils ont besoin d’aide. ",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 2 - L'Appui",
      "description": "Dans ce deuxième chapitre, Édith et Michel relatent la période où la maladie de leur conjoint respectif progresse et les difficultés s’accumulent."
    },
    "image_poster": "/images/videocovers/cover-chap-2.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_2.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/subtitles/ST-ANG_2.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/subtitles/ST-FR_2.vtt",
      }
    },
    "outils": {
      "titre": "S’organiser, demander et obtenir de l’aide",
      "themes": [
        "L’alourdissement des tâches et l’élargissement du rôle de la personne proche aidante (le vécu, le ressenti, etc.)",
        "Les services : où sont-ils\xa0? Comment y accéder\xa0? Est-ce qu’ils conviennent à ma situation\xa0? Les avantages et les inconvénients",
      ],
    }
  },
  "le-quotidien-a-domicile" : {
    "id": 3,
    "links": {
      "prev": "la-quete-de-services",
      "next": "la-separation",
    },
    "titre": "Le quotidien à domicile",
    "description": "Édith et Michel racontent leur vie avec une personne malade. Plus la maladie progresse, plus le quotidien s’alourdit. Une distance se crée dans la relation affective. L’épuisement s’installe.",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 3 - L'Appui",
      "description": "Dans ce troisième chapitre, Édith et Michel racontent leur quotidien avec une personne malade : plus la maladie progresse, plus le quotidien s’alourdit."
    },
    "image_poster": "/images/videocovers/cover-chap-3.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_3.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/subtitles/ST-ANG_3.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/subtitles/ST-FR_3.vtt",
      }
    },
    "outils": {
      "titre": "Vivre un quotidien de plus en plus lourd et apprendre à prendre soin de soi",
      "themes": [
        "Jouer plusieurs nouveaux rôles et réaliser de nouvelles tâches",
        "Prévenir l’épuisement",
      ],
    }
  },
  "la-separation" : {
    "id": 4,
    "links": {
      "prev": "le-quotidien-a-domicile",
      "next": "la-vie-en-hebergement",
    },
    "titre": "La séparation",
    "description": "Édith et Michel abordent la transition extrêmement difficile vers l’hébergement de leur conjoint. Une décision complexe qui leur fait vivre de multiples émotions.",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 4 - L'Appui",
      "description": "Dans ce quatrième chapitre, Édith et Michel abordent la transition extrêmement difficile vers l’hébergement de leur conjoint."
    },
    "image_poster": "/images/videocovers/cover-chap-4.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_4.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/subtitles/ST-ANG_4.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/subtitles/ST-FR_4.vtt",
      }
    },
    "outils": {
      "titre": "Envisager l’hébergement et prendre la décision",
      "themes": [
        "Affronter les difficultés de l’hébergement",
        "Apprendre à faire son (ses) deuil(s)",
      ],
    }
  },
  "la-vie-en-hebergement" : {
    "id": 5,
    "links": {
      "prev": "la-separation",
      "next": "quand-la-vie-resurgit",
    },
    "titre": "La vie en hébergement",
    "description": "Édith et Michel se confient sur cette période de leur trajectoire où ils continuent à vivre leur rôle de proche aidant mais dans un autre milieu de vie, le CHSLD.",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 5 - L'Appui",
      "description": "Dans ce cinquième chapitre, Édith et Michel se confient sur la période pendant laquelle leur proche réside dans un autre milieu de vie, le CHSLD."
    },
    "image_poster": "/images/videocovers/cover-chap-5.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_5.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/subtitles/ST-ANG_.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/subtitles/ST-FR_.vtt",
      }
    },
    "outils": {
      "titre": "Redéfinir son rôle de personne proche aidante en contexte d’hébergement",
      "themes": [
        "S’ajuster à l’hébergement et négocier son rôle de personne proche aidante",
        "Comment conserver le lien dans la relation\xa0?",
      ],
      "extra": [
        "Testons quelques-unes de nos croyances en lien avec l’hébergement",
        "Aider les personnes proches aidantes à choisir leurs combats",
      ],
    }
  },
  "quand-la-vie-resurgit" : {
    "id": 6,
    "links": {
      "prev": "la-vie-en-hebergement",
      "next": "la-fin-de-vie-de-la-personne-aidee",
    },
    "titre": "Quand la vie resurgit",
    "description": "Édith et Michel évoquent leur rencontre alors que leurs chemins se sont croisés par hasard. Ils nous racontent leur expérience de retomber en amour alors qu’ils prennent toujours soin de leur conjoint malade.",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 6 - L'Appui",
      "description": "Dans ce sixième chapitre, Édith et Michel évoquent leur rencontre alors que leurs chemins se sont croisés par hasard. Ils redécouvrent l'amour."
    },
    "image_poster": "/images/videocovers/cover-chap-6.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_6.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/subtitles/ST-ANG_7.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/subtitles/ST-FR_7.vtt",
      }
    },
    "outils": {
      "titre": "Retomber en amour alors que l’on est toujours proche aidant",
      "themes": [
        "Répondre aux besoins d’affection, d’écoute et d’intimité",
        "Briser un tabou",
      ],
    }
  },
  "la-fin-de-vie-de-la-personne-aidee" : {
    "id": 7,
    "links": {
      "prev": "quand-la-vie-resurgit",
      "next": "les-premiers-symptomes",
    },
    "titre": "La fin de vie de la personne aidée",
    "description": "Édith et Michel racontent une épreuve poignante, celle de l'accompagnement de leur conjoint malade dans ses derniers moments de vie.",
    "meta": {
      "title": "Témoignage de proche aidant - Chapitre 7 - L'Appui",
      "description": "Dans ce dernier chapitre, Édith et Michel racontent l'accompagnement de leur conjoint malade dans ses derniers moments de vie."
    },
    "image_poster": "/images/videocovers/cover-chap-7.jpg",
    "video": "/videos/Au-Dela_Des_Mots-Chapitre_7.mp4",
    "subs": {
      "en": {
        "label" : "English",
        "url": "/videos/SOP19113-INFO_AIDANT-MASTER-01-WEB-EN-YTB.vtt",
      },
      "fr": {
        "label": "Français",
        "url": "/videos/SOP19113-INFO_AIDANT-MASTER-01-WEB-FR-YTB.vtt",
      }
    },
    "outils": {
      "titre": "Se préparer à vivre les derniers moments de notre proche",
      "themes": [
        "L’accompagnement en fin de vie",
        "Deuil et post-aidance",
      ],
    }
  },
}]

export default chapitres;
