<template>
  <div class="container">
    <div class="shadow"></div>
    <div class="content text-center w-100">
      <div class="h1 mb-4">
        404 - Not Found
      </div>
      <p class="h3">Cette page n'existe pas</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
}
</script>
