<template>
  <div class="w-100 h-100">
    <div class="shadow"></div>
    <div class="container content pt-menu d-block z-top">

      <section class="row mt-5 mb-3 align-items-center justify-content-between">
        <div class="col-auto">
          <h1>Le Balado</h1>
        </div>
        <div class="col-auto align-items-center">
          <span class="pr-3">Pensé et produit par</span>
          <img src="/images/Logo_SiteWeb.svg" alt="Logo Appui Nav" width="116" height="52">
        </div>
      </section>

      <section class="mb-4">
        <img src="/images/bandeau.png" alt="Campagne" class="img-fluid w-100">
      </section>

      <section class="row">
        <div class="col-md-8 col-sm-12">
          <p class="mb-0">Restez à l’affût! Le nouveau projet audio « Des histoires qui résonnent : le balado des proches aidants » sera en ligne très bientôt. Ce balado est l’occasion de tendre le micro aux personnes proches aidantes afin qu’elles nous racontent leur vécu. D’ici là, aidez-nous à en enrichir le contenu en témoignant de votre expérience de proche aidance.</p>
        </div>
        <div class="col-md-4 col-sm-12 mt-3 mt-sm-0">
          <router-link :to="{name: 'BaladoTestimonial'}" class="font-weight-bold btn btn-light btn-block">
            Témoignez de votre expérience
          </router-link>
        </div>
      </section>

      <section class="row my-4">
        <div class="col-auto">
          <p class="big font-serif">Abonnez-vous pour ne manquer aucun épisode :</p>
        </div>
        <div class="col-auto pl-0">
          <a href="https://open.spotify.com/show/5IEMDp5UC4wxg1ACiKbLRg" target="_blank">
            <img src="/images/icons/spotify.png" alt="Spotify">
          </a>
        </div>
        <div class="col-auto pl-0">
          <a href="https://podcasts.apple.com/us/podcast/des-histoires-qui-r%C3%A9sonnent-le-balado-des-proches-aidants/id1564893544" target="_blank">
            <img src="/images/icons/other-streaming.png" alt="">
          </a>
        </div>
      </section>

      <section class="mb-5 mt-5">
        <div class="mb-5">
          <p class="h3 fw-bold">Tous les épisodes</p>
        </div>
        <div class="episodes">
          <template v-for="(balado, index) in balados" :key="balado.id">
            <Episode :ep="balado.id"
                     :slug="index"
                     :title="balado.title"
                     :description="balado.description"
                     :src="balado.src"
                     :image="balado.image" />
          </template>
        </div>
      </section>
      <router-view v-slot="slotProps">
        <transition name="toaster" mode="out-in">
          <component :is="slotProps.Component" :key="$route.params.slug"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
  import Episode from '../components/Episode';

  export default {
    name: 'Balado',
    components: {
      Episode,
    },
    mounted() {
    },
    data: () => ({
      data: {
      }
    }),
    methods: {
    }
  }
</script>
