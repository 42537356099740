<template>
  <div>
    <div class="content w-100 pt-menu">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="container">
          <div class="main-swiper-wrapper w-100 position-relative pt-4">

            <swiper
              ref="imagesSwiper"
              :slides-per-view="1"
              :speed="800"
              navigation
              :loop="true"
              :loopAdditionalSlides="5"
              :autoDestroy="true"
              class="photos-swiper"
            >
              <swiper-slide v-for="(chapitre,index) in videosArtisans" :key="chapitre.id">
                <router-link :to="{name:'SingleArriereSceneArtisans',params: { id: index }}">
                  <div class="position-relative d-inline-block pl-sm-5 pr-5">
                    <img :src="chapitre.image_poster" alt="" class="img-fluid">
                    <p class="text-left position-absolute caption pl-sm-5 pr-5">
                      <span>{{ chapitre.titre }}</span>
                    </p>
                    <p class="text-right mt-1 mb-0 credits">&nbsp;</p>
                    <svg class="play-btn" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" @click="play">
                      <g transform="translate(-639 -299.333)">
                        <g stroke="#fff" stroke-width="1.5px" class="bg-play" transform="translate(639 299.333)">
                          <circle stroke="none" cx="30" cy="30" r="30" />
                          <circle fill="none" cx="30" cy="30" r="29.25" />
                        </g>
                        <path fill="#fff" d="M9.274,0l9.274,19.209H0Z" transform="translate(681.062 319.921) rotate(90)" />
                      </g>
                    </svg>
                  </div>
                </router-link>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation]);

export default {
  name: 'ArriereSceneArtisans',
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
  }
}
</script>
