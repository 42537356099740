<template>
  <div class="row align-items-center audio-player text-dark">
    <router-link :to="{name: 'Balado'}" class="close-player">
      <div class="col-auto mb-4 cursor-pointer">X</div>
    </router-link>
    <div class="col-auto order-1">
      <button type="button" class="btn btn-link p-0" @click="play(false)" v-show="playing">
        <img src="/images/icons/pause-dark.svg" alt="icone pause lecture">
      </button>
      <button type="button" class="btn btn-link p-0" @click="play(true)" v-show="!playing">
      <img src="/images/icons/lecture-dark.svg" alt="icone lecture" @click="play(true)" v-show="!playing">
      </button>
    </div>
    <div class="col-auto order-1" v-if="ep && title">
      <p class="font-serif mb-0 big">
        <em v-if="ep == 'teaser'">— Bande annonce</em>
        <em v-else>— Episode {{ ep }}</em>
      </p>
      <h3 class="font-weight-bold mb-3">{{ title }}</h3>
    </div>
    <div class="col-auto order-1 ml-auto">
      <p class="mb-0 big time" :key="$route.params.slug">
        {{ humanCurrentTime }}
        /
        {{ humanDuration }}
      </p>
    </div>
    <div :class="[ep && title ? 'col-12 order-0' : 'col order-1']" >
      <div  class="audio_progress_wrapper py-2"
            @mousedown="seek($event,true)"
            @mouseup="clickToSeek = false"
            @mousemove="seek"
            @mouseleave="clickToSeek = false"
            @touchmove="seek($event,true)">
        <div class="audio_progress_bg">
          <div class="audio_progress" :style="'width:'+ position +'%'"></div>
        </div>
      </div>
    </div>
    <audio
        controls="false"
        class="d-none"
        :src="src">
            Your browser does not support the
            <code>audio</code> element.
    </audio>
  </div>
</template>

<script>
export default {
  name: 'AudioPlayer',
  data: () => ({
    currentTime: 0,
    position: 0,
    duration: 0,
    audioPlayer: null,
    playing: false,
  }),
  props: ['src', 'ep', 'title'],
  methods: {
    progressLoop() {
      this.currentTime = this.audioPlayer.currentTime
      this.position = this.audioPlayer.currentTime * 100 / this.duration
      if(this.position == 100) {
        this.audioPlayer.pause();
        this.playing = false;
        this.audioPlayer.currentTime = 0
      }
    },
    seek(e,clicked) {
      if(clicked) {
        this.clickToSeek = true
      }
      if(this.clickToSeek) {
        let posX = e.clientX || e.touches[0].clientX
        this.position = (posX - e.target.getBoundingClientRect().left) * 100 / e.target.getBoundingClientRect().width
        this.audioPlayer.currentTime = this.duration * this.position / 100;
      }
    },
    play(play) {
      if(!play) {
        this.audioPlayer.pause();
        this.playing = false;
      } else {
        this.playing = true;
        this.audioPlayer.play();
      }
    },
    updateTime() {
      console.log('watch duration',this.audioPlayer.duration)
      this.duration = document.querySelector('audio').duration
    }
  },
  mounted() {
    this.audioPlayer = document.querySelector('audio');
    this.audioPlayer.onloadedmetadata = () => {
      this.duration = this.audioPlayer.duration
    };
    this.audioPlayer.addEventListener("timeupdate", this.progressLoop);
    this.play(true)
  },
  unmounted() {
    this.audioPlayer.removeEventListener("timeupdate", this.progressLoop);
    this.duration = this.audioPlayer.duration
  },
  computed: {
    humanCurrentTime: function () {
      return ("0" + Math.floor(this.currentTime % 3600 / 60)).slice(-2) +":"+ ("0" + Math.floor(this.currentTime % 60)).slice(-2)
    },
    humanDuration: function () {
      return ("0" + Math.floor(this.duration % 3600 / 60)).slice(-2) +":"+ ("0" + Math.floor(this.duration % 60)).slice(-2)
    }
  },
}
</script>
