<template>
  <div>
    <div class="content w-100 pb-5">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="container pt-menu">
          <div class="collaborateurs-swiper-wrapper position-relative pt-4">
            <swiper
              ref="creditsSwiper"
              :slides-per-view="1"
              :breakpoints="{991: {slidesPerView: 2}}"
              :space-between="24"
              :speed="800"
              navigation
              :loop="true"
              :loopAdditionalSlides="1"
              :autoDestroy="true"
              class="float-left"
              @swiper="setControlledSwiper"
            >
              <swiper-slide class="position-relative d-inline-block bg-white p-4 text-dark text-left">
                <h4 class="mb-4 font-weight-bold">Coordination et réalisation vidéo</h4>
                <p><strong>Coordination et gestion de projet</strong><br>
                Julie Bickerstaff C. - L’Appui pour les proches aidants</p>

                <p><strong>Textes et interprétation</strong><br>
                Édith Fournier et Michel Carbonneau</p>
                <p><strong>Réalisation, direction image et montage</strong><br>
                Josué Bertolino et Les Productions vives (2020)</p>
                <p><strong>Scénario et mise en scène</strong><br>
                Guillaume Chouinard</p>
                <p><strong>Musique et conception sonore</strong><br>
                Éric Forget</p>
              </swiper-slide>

              <swiper-slide class="position-relative d-inline-block bg-white p-4 text-dark text-left">
                <h4 class="mb-4 font-weight-bold">Outils d'animation</h4>
                <p><strong>Conception et rédaction des outils d’animation</strong><br>
                • Julie Bickerstaff C. - L’Appui pour les proches aidants<br>
                • Pierre Grenier, consultant</p>
                <p><strong>Collaboration à la révision des outils d’animation</strong><br>
                • Élaine Clavet - Résidence Berthiaume-Du Tremblay <br>
                • Huguette Page - Société Alzheimer Montréal <br>
                • Taline Ladayan - Services de soutien à la famille Hay Doun <br>
                • Stéphanie Ouellet - Groupe des aidants du Sud Ouest <br>
                • Marion Mutin - Société Alzheimer Granby et Région <br>
                • Émilie Therrien - Maison de la famille des Maskoutains <br>
                • Franca Turco - Y des femmes de Montréal <br>
                • Katerine Ouimet – Travailleuse sociale<br>
                </p>

              </swiper-slide>

              <swiper-slide class="position-relative d-inline-block bg-white p-4 text-dark text-left">
                <h4 class="mb-4 font-weight-bold">Conception du webdocumentaire</h4>
                <p><strong>Révision des textes</strong><br>
                Laurie Marcellesi et Geneviève V. Bérubé, agence Wink Stratégies</p>
                <p><strong>Conception graphique</strong><br>
                Lisa Ioux et Alvin Kherraz, agence Wink Stratégies</p>
                <p><strong>Conception web</strong><br>
                Romane Gattolin et Thomas Popoff, agence Wink Stratégies</p>
                <p><strong>Traduction</strong><br>
                Rebecca Bates</p>

              </swiper-slide>

              <swiper-slide class="position-relative d-inline-block bg-white p-4 text-dark text-left">
                <h4 class="mb-4 font-weight-bold">Remerciements</h4>
                <p>L’Appui remercie chaleureusement l’ensemble des artisanes et des artisans de ce projet. Toutes et tous à leur manière, ont apporté leur expertise et leur talent pour faire de cette œuvre un projet collectif porteur. Les mots d’Édith et Michel pourront ainsi traverser le temps et apporter du réconfort à des générations de personnes proches aidantes!</p>
                <p>Nous tenons à remercier particulièrement Édith et Michel pour la confiance qu’ils nous ont accordée, mais surtout pour la création de cette œuvre qui témoigne, sans pudeur, des joies, des doutes et des deuils que vivent les personnes proches aidantes qui accompagnent dans l’amour un être cher.</p>
              </swiper-slide>

            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Controller } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Controller]);

export default {
  name: 'ArriereSceneCollaborateurs',
  components: {
    Swiper,
    SwiperSlide,
  },
	data: () => ({
    controlledSwiper: null,
  }),
  mounted() {
    console.log(this.$route)
  },
  methods: {
    setControlledSwiper(swiper) {
      this.controlledSwiper = swiper;
    },
  }
}
</script>
