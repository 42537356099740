const balados = [{
  "bande-annonce": {
    "id": 'teaser',
    "title": "1: Bande-annonce - Des histoires qui résonnent: le Balado des proches aidants",
    "description": "Ici Marina Orsini. J’ai le plaisir d’animer ce printemps l’émission “Des histoires qui résonnent : le Balado des proches aidants.”Saviez-vous qu’une personne sur quatre est proche aidante au Québec? Il s’agit peut-être de votre voisin, de votre mère, voire de vous.",
    "src": "/audios/episode1.wav",
    "image": "/images/vignette2.png",
  },
  "slug_1": {
    "id": 1,
    "title": "",
    "description": "",
    "src": "",
    "image": "/images/vignette2.png",
  },
  "slug_2": {
    "id": 2,
    "title": "",
    "description": "",
    "src": "",
    "image": "/images/vignette2.png",
  },
  "slug_3": {
    "id": 3,
    "title": "",
    "description": "",
    "src": "",
    "image": "/images/vignette2.png",
  },
  "slug_4": {
    "id": 4,
    "title": "",
    "description": "",
    "src": "",
    "image": "/images/vignette2.png",
  },
  "slug_5": {
    "id": 5,
    "title": "",
    "description": "",
    "src": "",
    "image": "/images/vignette2.png",
  },
  "slug_6": {
    "id": 6,
    "title": "",
    "description": "",
    "src": "",
    "image": "/images/vignette2.png",
  },
}];

export default balados;