// https://www.vuemastery.com/blog/vue-router-a-tutorial-for-vue-3/

import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";

import Balado from "@/views/Balado.vue";
import BaladoSingle from "@/views/BaladoSingle.vue";
import BaladoTestimonial from "@/views/BaladoTestimonial.vue";

import Chapitres from "@/views/Chapitres.vue";
import SingleChapitre from "@/views/SingleChapitre.vue";

import Page from "@/views/Page.vue";

import OutilsAnimationHome from "@/views/OutilsAnimationHome.vue";
import OutilsAnimationForm from "@/views/OutilsAnimationForm.vue";
import OutilsAnimationDetails from "@/views/OutilsAnimationDetails.vue";
import OutilsAnimationDownload from "@/views/OutilsAnimationDownload.vue";

import AuDelaDesChiffresHome from "@/views/AuDelaDesChiffresHome.vue";
import AuDelaDesChiffresRessources from "@/views/AuDelaDesChiffresRessources.vue";
import AuDelaDesChiffresObjectifs from "@/views/AuDelaDesChiffresObjectifs.vue";

import ArriereScene from "@/views/ArriereScene.vue";
import ArriereSceneArtisans from "@/views/ArriereSceneArtisans.vue";
import ArriereScenePhotos from "@/views/ArriereScenePhotos.vue";
import ArriereSceneCollaborateurs from "@/views/ArriereSceneCollaborateurs.vue";
import SingleArriereSceneArtisans from "@/views/SingleArriereSceneArtisans.vue";


import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Au-delà des Mots - Série documentaire par L'Appui",
      description: "L'Appui pour les proches aidants présente sa série documentaire, qui regroupe des témoignages et des outils essentiels pour les proches aidants."
    }
  },
  {
    path: "/serie-documentaire",
    name: "Chapitres",
    component: Chapitres,
    meta: {
      title: "Récit de proches aidants en 7 chapitres - L'Appui",
      description: "Découvrez en vidéo les récits d'Édith et Michel, deux proches aidants qui ont accompagné leur conjoint malade pendant leurs derniers moments de vie."
    },
  },
  {
    path: '/serie-documentaire/:id',
    name: "SingleChapitre",
    component: SingleChapitre,
  },
  {
    path: "/outils-animation",
    name: "Page",
    component: Page,
    children: [
      {
        path: '',
        name: "OutilsAnimationHome",
        component: OutilsAnimationHome,
        meta: {
          title: "Outils d'animation - Au-delà des Mots - L'Appui",
          description: "L’Appui pour les proches aidants vous offre des outils d’animation pour vous accompagner dans vos activités de soutien auprès des proches aidants."
        }
      },
      {
        path: 'details',
        name: "OutilsAnimationDetails",
        component: OutilsAnimationDetails,
        meta: {
          title: "Téléchargez les outils d'animation - Au-delà des Mots",
          description: "Sept fiches d'animation sont à votre disposition pour accompagner les persones proches aidantes dans toutes leurs épreuves."
        }
      },
      {
        path: 'demande',
        name: "OutilsAnimationForm",
        component: OutilsAnimationForm,
        meta: {
          title: "Demandez les outils d'animation - Au-delà des Mots",
          description: "Pour recevoir les outils d’animation gratuitement, l'Appui vous invite à compléter un formulaire. Obtenez ensuite tous les documents souhaités."
        }
      },
      {
        path: 'telechargement',
        name: "OutilsAnimationDownload",
        component: OutilsAnimationDownload,
        meta: {
          title: "Téléchargement des outils d'animation - Au-delà des Mots",
          description: "Téléchargez gratuitement les sept fiches qui vous accompagneront dans l'animation des groupes de soutien pour les personnes proches aidantes."
        }
      },
    ]
  },
  {
    path: "/au-dela-des-chiffres",
    name: "AuDelaDesChiffres",
    component: Page,
    children: [
      {
        path: '',
        name: "AuDelaDesChiffresHome",
        component: AuDelaDesChiffresHome,
        meta: {
          title: "Au-delà des chiffres - L'Appui pour les proches aidants",
          description: "Au Québec, plus de 141 000 personnes sont touchées par la maladie d'Alzheimer. Ce chiffre pourrait grimper à 260 000 d’ici 15 ans."
        }
      },
      {
        path: 'ressources',
        name: "AuDelaDesChiffresRessources",
        component: AuDelaDesChiffresRessources,
        meta: {
          title: "Les ressources - Au-delà des Mots - L'Appui",
          description: "Ligne d'écoute, répertoire de ressources, informations et conseils... Découvrez tous les services de l'Appui à votre disposition."
        }
      },
      {
        path: 'objectifs',
        name: "AuDelaDesChiffresObjectifs",
        component: AuDelaDesChiffresObjectifs,
        meta: {
          title: "Objectifs - Au-delà des Mots - L'Appui",
          description: "L’objectif premier de l’Appui est d’améliorer la qualité de vie des personnes proches aidantes. Découvrez plus d'informations sur son action au quotidien."
        }
      },
    ]
  },
  {
    path: "/arriere-scene",
    name: "ArriereScene",
    redirect: {name: "ArriereSceneArtisans"},
    component: ArriereScene,
    children: [
      {
        path: 'artisans',
        name: "ArriereSceneArtisans",
        component: ArriereSceneArtisans,
        props: { playing: false },
        meta: {
          title: "En tête-à-tête avec les artisans - Au-delà des Mots",
          description: "Découvrez les coulisses des sept chapitres de la série documentaire « Au-delà des Mots », produite en collaboration avec l'Appui pour les proches aidants."
        }
      },
      {
        path: 'photos',
        name: "ArriereScenePhotos",
        component: ArriereScenePhotos,
        meta: {
          title: "Photos - Au-delà des Mots - L'Appui",
          description: "Découvrez toutes les photos prises lors de la réalisation d'« Au-delà des Mots », la série documentaire de l'Appui pour les proches aidants."
        }
      },
      {
        path: 'collaborateurs',
        name: "ArriereSceneCollaborateurs",
        component: ArriereSceneCollaborateurs,
        meta: {
          title: "Collaborateurs - Au-delà des Mots - L'Appui",
          description: "Découvrez tous les collaborateurs qui ont participé à la réalisation d'« Au-delà des Mots », la série documentaire de l'Appui pour les proches aidants."
        }
      },
    ]
  },
  {
    path: '/arriere-scene/artisans/:id',
    name: "SingleArriereSceneArtisans",
    component: SingleArriereSceneArtisans,
    meta: {
      title: "",
      description: ""
    }
  },
  {
    path: "/balado",
    name: "Balado",
    component: Balado,
    meta: {
      title: "Balado - Au-delà des Mots - L'Appui",
      description: "Ce balado est l’occasion de tendre le micro aux personnes proches aidantes afin qu’elles nous racontent leur vécu. D’ici là, aidez-nous à en enrichir le contenu en témoignant de votre expérience de proche aidance.",
      ogImage: ""
    },
    children: [
      {
        path: ':slug',
        name: "BaladoSingle",
        component: BaladoSingle,
        // props: { playing: true },
        meta: {
          title: "Bande-annonce - Des histoires qui résonnent: le Balado des proches aidants",
          description: "Ici Marina Orsini. J’ai le plaisir d’animer ce printemps l’émission “Des histoires qui résonnent : le Balado des proches aidants.”Saviez-vous qu’une personne sur quatre est proche aidante au Québec? Il s’agit peut-être de votre voisin, de votre mère, voire de vous.",
          ogImage: "/images/vignette2.png"
        }
      },
    ]
  },
  {
    path: "/balado/temoignage",
    name: "BaladoTestimonial",
    component: BaladoTestimonial,
    meta: {
      title: "",
      description: ""
    }
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
