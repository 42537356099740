<template>
  <div class="position-relative">
    <svg id="bandeaux-video" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1311.4 948.8" class="position-absolute w-0 h-0">
      <defs>
        <clipPath id="clip-video" clipPathUnits="objectBoundingBox" transform="scale(0.000762543846271, 0.001053962900506)">
          <rect width="255.8" data-height="948.8" style="fill:none" />
          <rect x="263.9" y="90.7" width="255.8" data-height="719.2" style="fill:none" />
          <rect x="527.8" y="163.2" width="255.8" data-height="785.7" style="fill:none" />
          <rect x="791.7" width="255.8" data-height="870.3" style="fill:none" />
          <rect x="1055.6" y="90.7" width="255.8" data-height="858.2" style="fill:none" />
        </clipPath>
      </defs>
    </svg>

    <svg id="bandeaux" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1311.4 948.8" class="position-relative img-fluid">
      <defs>
        <clipPath id="clip-path">
          <rect width="255.8" data-height="948.8" style="fill:none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect x="263.9" y="90.7" width="255.8" data-height="719.2" style="fill:none" />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect x="527.8" y="163.2" width="255.8" data-height="785.7" style="fill:none" />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect x="791.7" width="255.8" data-height="870.3" style="fill:none" />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect x="1055.6" y="90.7" width="255.8" data-height="858.2" style="fill:none" />
        </clipPath>
        <g id="image">
          <transition-group name="fade-slow" mode="out-in">
            <image v-show="hover == 1 || hover == 0" :key="1" width="1225" height="1080" xlink:href="/images/img_accueil_chapitre_HR.jpg" />
            <image v-show="hover == 2" :key="2" width="1225" height="1080" xlink:href="/images/img_accueil__audeladeschiffre_HR.jpg" />
            <image v-show="hover == 3" :key="3" width="1225" height="1080" xlink:href="/images/img_accueil_outilsdanimation_HR.jpg" />
            <image v-show="hover == 4" :key="4" width="1225" height="1080" xlink:href="/images/img_accueil_arrierescene_HR.jpg" />
            <image v-show="hover == 5" :key="5" width="1225" height="1080" xlink:href="/images/IMG_survol.jpg" />
          </transition-group>
        </g>
      </defs>
      <router-link :to="{path:'/serie-documentaire'}">
        <g class="bandeau-fade" style="clip-path:url(#clip-path)" @mouseenter="hover = 1; hovered = true" @mouseleave="hovered = false">
          <use transform="scale(1.07)" xlink:href="#image" />
        </g>
      </router-link>
      <router-link :to="{path:'/outils-animation'}">
        <g class="bandeau-fade" style="clip-path:url(#clip-path-2)" @mouseenter="hover = 2; hovered = true" @mouseleave="hovered = false">
          <use transform="scale(1.07)" xlink:href="#image" />
        </g>
      </router-link>
      <router-link :to="{path:'/au-dela-des-chiffres'}">
        <g class="bandeau-fade" style="clip-path:url(#clip-path-3)" @mouseenter="hover = 3; hovered = true" @mouseleave="hovered = false">
          <use transform="scale(1.07)" xlink:href="#image" />
        </g>
      </router-link>
      <router-link :to="{path:'/arriere-scene'}">
        <g class="bandeau-fade" style="clip-path:url(#clip-path-4)" @mouseenter="hover = 4; hovered = true" @mouseleave="hovered = false">
          <use transform="scale(1.07)" xlink:href="#image" />
        </g>
      </router-link>
      <router-link :to="{path:'/balado'}">
        <g class="bandeau-fade" style="clip-path:url(#clip-path-5)" @mouseenter="hover = 5; hovered = true" @mouseleave="hovered = false">
          <use transform="scale(1.07)" xlink:href="#image" />
        </g>
      </router-link>
    </svg>

    <div class="big-h1 position-absolute w-100 d-flex justify-content-center">

      <transition name="fade" mode="out-in">
        <h2 v-if="hover == 1 && hovered == true">Série<br>documentaire</h2>
        <h2 v-else-if="hover == 2 && hovered == true">Outils<br>d'animations</h2>
        <h2 v-else-if="hover == 3 && hovered == true">Au-delà<br>des chiffres</h2>
        <div v-else-if="hover == 4 && hovered == true">
          <h2>Arrière-scène</h2>
          <div class="border-left border-white pl-3 mt-3">
            <p class="font-serif big mb-2">En tête-à-tête avec les artisans</p>
            <p class="font-serif big mb-2">Photos</p>
            <p class="font-serif big mb-0">Collaborateurs</p>
          </div>
        </div>
        <h2 v-else-if="hover == 5 && hovered == true">Balado</h2>
      </transition>
    </div>

    <!-- <transition name="fade"> -->
      <video v-show="!hovered" id="bandeaux-video-bg" loop autoplay muted playsinline class="position-absolute w-100 h-100">
        <source src="/videos/Au-Dela_Des_Mots-Teaser-Versionsite-1.mp4" type="video/mp4">
      </video>
    <!-- </transition> -->

  </div>
</template>

<script>
export default {
  name: 'Bandeaux',
  data: () => ({
    hover: 0,
    hovered: false,
  }),
  methods: {
  },
  mounted() {
    let video = document.getElementById("bandeaux-video-bg");
    let dataHeight = document.querySelectorAll('[data-height]');
    video.oncanplaythrough = function() {
      console.log("Browser has loaded the current frame");
      dataHeight.forEach((val) => {
        val.style.height = val.getAttribute('data-height')+'px';
      });
    };
  }
}
</script>
