<template>
  <div class="my-5 pt-3 pt-md-4 pt-lg-5">
    <div class="row my-4 align-items-center">
      <div class="col-12 col-lg-auto">
        <h2 class="h1">Au-delà des chiffres</h2>
      </div>
      <div class="col-auto my-4">
        <router-link :to="{name: 'AuDelaDesChiffresHome'}" class="btn btn-outline-light">
          <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="d-none d-sm-inline-block mr-3" width="28.91" height="19.27">
          Retour
        </router-link>
      </div>
      <div class="col-auto my-4 ml-auto">
        <router-link :to="{name: 'AuDelaDesChiffresObjectifs'}" class="btn btn-outline-light">
          L'objectif de l'Appui
          <img src="/images/icons/arrow-right-white.svg" alt="Flèche droite" class="d-none d-sm-inline-block ml-3" width="28.91" height="19.27">
        </router-link>
      </div>
    </div>

    <div class="row justify-content-around">
      <div class="col-12 col-md-8 col-lg-8 col-xl-4 mb-4">
        <div class="border border-white p-4 h-100 d-flex justify-content-between flex-column">
          <div class="text-center mb-3">
            <img src="/images/Logo-info-aidant.svg" alt="Logo info aidants" class="mb-4 img-fluid">
            <p><strong>Info-aidant est un service gratuit et confidentiel qui permet de trouver des ressources dans une région ou de bénéficier de conseils personnalisés.</strong></p>
            <p>Le service est disponible du lundi au dimanche de 8h à 20h.</p>
          </div>
          <a href="tel:+18558527784" class="btn btn-block d-flex justify-content-center btn-light align-items-center font-sans-serif py-3">
            <img src="/images/icons/phone.svg" alt="Logo téléphone su fond orange" class="mr-2" width="29" height="29">
            <span class="small font-weight-bold">Appelez-nous au 1 855 852-7784</span>
          </a>
          <a href="mailto:info-aidant@lappui.org" class="btn btn-block d-flex justify-content-center btn-light align-items-center font-sans-serif py-3">
            <img src="/images/icons/mail.svg" alt="Logo téléphone su fond orange" class="mr-2" width="29" height="29">
            <span class="small font-weight-bold">info-aidant@lappui.org</span>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
        <div class="bg-white p-4 text-dark h-100 d-flex justify-content-between flex-column">
          <div>
            <h4 class="mb-3 font-weight-bold">Un répertoire de ressources en ligne</h4>
            <p><strong>Rassemble les services disponibles pour les proches aidants partout au Québec.</strong></p>
            <p>Que ce soit pour du répit à domicile, des séances d’information, de l’aide au transport en passant par du soutien individuel, les proches aidants peuvent accéder en quelques clics aux ressources disponibles près de chez eux, grâce à une recherche avancée par types de services, mots clés ou codes postaux.</p>
          </div>
          <a href="https://www.lappui.org/Trouver-des-ressources/Repertoire-des-ressources" target="_blank" class="btn btn-block d-flex justify-content-between btn-outline-dark px-4 align-items-center">
            Découvrir les ressources
            <img src="/images/icons/arrow-right-dark.svg" alt="Flèche droite" class="ml-3" width="28.91" height="19.27">
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
        <div class="bg-white p-4 text-dark h-100 d-flex justify-content-between flex-column">
          <div>
            <h4 class="mb-3 font-weight-bold">De l’information sur la maladie d’Alzheimer et des conseils pratiques</h4>
            <p>L’objectif est de transmettre de l’information sur la maladie et les soins à prodiguer à une personne qui en est atteinte. Apprenez comment la maladie l’affectera, quels changements surviendront, comment vous pouvez favoriser les interactions avec elle et l’aider à conserver son autonomie et sa qualité de vie. Découvrez aussi des pistes pour mieux prendre soin de vous-mêmes.</p>
          </div>
          <a href="https://www.lappui.org/Conseils-pratiques/Alzheimer-et-autres-maladies-neurodegeneratives" target="_blank" class="btn btn-block d-flex justify-content-between btn-outline-dark px-4 align-items-center">
            En savoir plus
            <img src="/images/icons/arrow-right-dark.svg" alt="Flèche droite" class="ml-3" width="28.91" height="19.27">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuDelaDesChiffresRessources',
}
</script>
