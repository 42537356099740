<template>
  <div class="position-fixed fixed-bottom bg-white w-100 px-4 px-md-5 py-3 py-md-4" v-if="balados[$route.params.slug].src">
    <AudioPlayer :src="balados[$route.params.slug].src" :ep="balados[$route.params.slug].id" :title="balados[$route.params.slug].title" />
  </div>
</template>

<script>
import AudioPlayer from '../components/AudioPlayer';

export default {
  name: 'BaladoSingle',
  data: () => ({
  }),
  components: {
    AudioPlayer
  },
  mounted() {
  },
  methods: {
  }
}
</script>