<template>
  <div class="container">
    <div class="shadow"></div>
    <div class="content pt-menu">
      <div class="row justify-content-between align-items-center pt-5">
        <div class="col-12 col-md-7 col-lg-7 order-2 order-md-1 mt-5 mt-md-0">
          <Bandeaux/>
        </div>
        <div class="col-12 col-md-5 col-lg-5 col-xl-4 order-1 order-md-2">
          <img src="/images/Accueil_titre_logo.svg" alt="" class="img-fluid" width="200">
          <p class="big font-serif py-3 border-bottom border-white">Paroles de proches aidants</p>
          <div class="custom-scrollbar">
            <p><strong>Édith Fournier et Michel Carbonneau sont les auteurs de «&nbsp;Au-delà des mots : paroles de proches aidants&nbsp;».</strong> Il s’agit d'un récit à deux voix&nbsp;: une femme et un homme qui racontent, en alternance, ce qu’ils ont vécu auprès de leurs conjoints atteints de la maladie d’Alzheimer.</p>
            <p>Édith s’est occupée de son époux pendant 7&nbsp;ans à domicile et autant d’années en CHSLD. Michel  a accompagné son épouse pendant 6&nbsp;ans à domicile et pendant 8&nbsp;ans en CHSLD. Entre 2012 et 2019, ils ont partagé la scène pour présenter leur histoire à travers le Québec, ainsi qu’en Belgique, en France et au Luxembourg. Ils en font aujourd’hui un documentaire.</p>
            <p>L’Appui pour les proches aidants est fier de s’être associé à Édith et Michel afin d’offrir cette série de vidéos et d’outils d’animation. L’œuvre devient ainsi une référence pour des activités de soutien psychosocial proposées aux proches aidants au sein de différents types d’organisations.</p>
            <p>Les parcours d’Édith et de Michel sont touchants et à la fois singuliers et universels… Toutes et tous peuvent se reconnaître dans les défis que représente le fait de prendre soin d’un proche malade ou en perte d’autonomie. Leur récit fera écho à ceux de plusieurs personnes qui sont ou seront plongées dans cette réalité parfois difficile, souvent émouvante et tendre.</p>
          </div>
          <div class="row mt-4 d-none d-md-flex">
            <div class="col-auto">
              <button type="button" class="btn btn-outline-light p-0 mr-4" @click="scroll('top')">
                <img src="/images/icons/Fleche_Haut.svg" alt="fleche scroll haut">
              </button>
              <button type="button" class="btn btn-outline-light p-0" @click="scroll('bottom')">
                <img src="/images/icons/Fleche_Bas.svg" alt="fleche scroll bas">
              </button>
            </div>
          </div>
          <img src="/images/Logo_SiteWeb.svg" alt="Logo appui" class="mt-5" width="116" height="52">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bandeaux from '../components/Bandeaux.vue'
import easyScroll from 'easy-scroll';

export default {
  name: 'Home',
  components: {
    Bandeaux,
  },
  mounted() {
  },
  methods: {
    scroll(direction) {
      easyScroll({
        'scrollableDomEle': document.querySelector('.custom-scrollbar'),
        'direction': direction,
        'duration': 350,
        'easingPreset': 'easeInOutQuad',
        'scrollAmount': document.querySelector('.custom-scrollbar').offsetHeight / 2
      });
    }
  }
}
</script>
