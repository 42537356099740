<template>

  <div class="pb-3 mb-3 border-bottom position-relative episode">
    <div class="episodes-overlay" v-if="!src"></div>
    <div class="row align-items-center d-block d-md-flex">
      <div class="col-12 col-lg-4 col-xl-4 mb-3 mb-lg-0">
        <div class="bg-cover py-4 px-3 " :class="[!src ? 'opacity-04' : '']" style="min-height: 180px;" :style="{ backgroundImage: 'url(' + image + ')' }">
          <div class="row" v-if="src">
            <div class="col-12 col-md-10 col-lg-9 col-xl-8 ml-auto text-center image">
              <!-- <h3 class="font-weight-bold mb-3 font-sans-serif">{{ src ? title : 'À venir' }}</h3> -->
              <div class="row align-items-center justify-content-center">
                <div class="col-auto pr-0">
                  <router-link :to="{name:'BaladoSingle',params: { slug: slug }}">
                    <img src="/images/icons/lecture.svg" alt="Jouer le podcast" />
                  </router-link>
                </div>
                <div class="col-auto pl-0">
                  <div class="ml-3">
                    <p class="font-serif h5 mb-0" v-if="ep == 'teaser'"><em>Bande annonce</em></p>
                    <p class="font-serif h5 mb-0" v-else><em>Episode {{ ep }}</em></p>
                    <p class="font-serif h4 font-weight-bold">{{ this.duration }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <p class="font-serif mb-0">
          <em v-if="ep == 'teaser'">— Bande annonce</em>
          <em v-else>— Épisode {{ ep }}</em>
        </p>
        <h3 class="font-weight-bold mb-3">{{ src ? title : 'À venir' }}</h3>
        <p v-if="src">{{ description ? description : '' }}</p>
      </div>
      <div class="col-auto align-self-end" v-if="src">
        <span class="cursor-pointer" @click="showShares()"><img src="/images/icons/share.svg" alt="share" class="mr-2"/>Partager</span>
        <div class="mt-2 pt-1" v-if="showSocials">
          <a class="btn btn-outline-light btn-block btn-sm rounded-0" :href="getFacebookLink()" target="_blank">Facebook</a>
          <a class="btn btn-outline-light btn-block btn-sm rounded-0" :href="getTwitterLink()" target="_blank">Twitter</a>
          <a class="btn btn-outline-light btn-block btn-sm rounded-0" :href="getMailToLink()" target="_blank">Courriel</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Episode',
  props: ['ep', 'title', 'description', 'src', 'slug', 'image'],
  components: {
  },
  data: () => ({
    showSocials: false,
    duration: 0
  }),
  methods: {
    showShares() {
      this.showSocials = !this.showSocials;
    },
    getMailToLink() {
      return 'mailto:remy.groleau@winkstrategies.com?subject=' + this.title + '&body='+document.location.origin+'/balado/'+this.slug
    },
    getFacebookLink() {
      return 'https://www.facebook.com/share.php?u='+document.location.origin+'/balado/'+this.slug
    },
    getTwitterLink() {
      return 'https://twitter.com/intent/tweet?text='+ this.title +'&url='+document.location.origin+'/balado/'+this.slug
    },
    getDurationOfFile() {
      let au = document.createElement('audio');
      au.src = this.src;
      au.addEventListener('loadedmetadata', (event) => {
        let duration = parseInt(event.target.duration).toFixed(2);
        let minutes = Math.floor(duration / 60);
        let seconds = duration - minutes * 60;

        this.duration = minutes + ' min ' + seconds;
      },false);
    }
  },
  mounted() {
    this.getDurationOfFile();
  }
}
</script>
