<template>
  <div>
    <div class="content w-100 pt-menu">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="container">
          <div class="main-swiper-wrapper w-100 position-relative pt-4">

            <swiper
              ref="imagesSwiper"
              :slides-per-view="1"
              :space-between="24"
              :speed="800"
              navigation
              class="photos-swiper"
              :loop="true"
              :loopAdditionalSlides="1"
              :autoDestroy="true"
              :preloadImages="false"
            >
              <swiper-slide v-for="(photo,photoIndex) in photos" :key="photo">
                <div class="position-relative d-inline-block pl-sm-5 pr-5">
                  <img :src="photo.path" alt="" class="img-fluid" @click="index = photoIndex">
                  <p class="text-left position-absolute caption pl-sm-5 pr-5">
                    <span>{{ photo.name }}</span>
                  </p>
                  <p class="text-right mt-1 mb-0 credits">Crédit photo : Laurent Dansereau</p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <PhotoGallery :images="photos" :index="index" @close="index = null"/>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Controller } from 'swiper';
import PhotoGallery from '../components/PhotoGallery.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Controller]);

export default {
  name: 'ArriereScenePhotos',
  components: {
    Swiper,
    SwiperSlide,
    PhotoGallery
  },
	data: () => ({
    index: null,
    photos: [
      {
        "path": "/images/photos/Photo-d_arrière-scène---Josué-Bertolino,-Guillaume-Chouinard,-Pascal-Tremblay.jpg",
        "name": "Photo d'arrière-scène - Josué Bertolino, Guillaume Chouinard, Pascal Tremblay"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Marie-Sophie-Roy,-Marie-Sophie-Roy,-Pascal-Tremblay.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Marie-Sophie Roy, Marie-Sophie Roy, Pascal Tremblay"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Marie-Sophie-Roy,-Samuel-Thériault,-Pascal-Tremblay-.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Marie-Sophie Roy, Samuel Thériault, Pascal Tremblay "
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Guillaume-Chouinard,-Josué-Bertolino.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Guillaume Chouinard, Josué Bertolino"
      },{
        "path": "/images/photos/Photo-d_arrière-scène----Michel-Carbonneau,-Marie-Sophie-Roy.jpg",
        "name": "Photo d'arrière-scène -  Michel Carbonneau, Marie-Sophie Roy"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Michel-Carbonneau,-Marie-Sophie-Roy.jpg",
        "name": "Photo d'arrière-scène - Michel Carbonneau, Marie-Sophie Roy"
      },{
        "path": "/images/photos/Photo-d_arrière-scène----Michel-Carbonneau,-Julien-Boisvert,-Josué-Bertolino.jpg",
        "name": "Photo d'arrière-scène -  Michel Carbonneau, Julien Boisvert, Josué Bertolino"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Michel-Carbonneau.jpg",
        "name": "Photo d'arrière-scène - Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Josué-Bertolino.jpg",
        "name": "Photo d'arrière-scène - Josué Bertolino"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Josué-Bertolino,-Marie-Sophie-Roy.jpg",
        "name": "Photo d'arrière-scène - Josué Bertolino, Marie-Sophie Roy"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Josué-Bertolino,-Julien-Boisvert,-Pascal-Tremblay,-Marie-Sophie-Roy.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Josué Bertolino, Julien Boisvert, Pascal Tremblay, Marie-Sophie Roy"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Josué-Bertolino,-Guillaume-Chouinard,-Julien-Boisvert.jpg",
        "name": "Photo d'arrière-scène - Josué Bertolino, Guillaume Chouinard, Julien Boisvert"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Guillaume-Chouinard,-Julien-Boisvert,-Marie-Sophie-Roy.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Guillaume Chouinard, Julien Boisvert, Marie-Sophie Roy"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Josué-Bertolino,-Julien-Boisvert.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Josué Bertolino, Julien Boisvert"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Josué-Bertolino,-Guillaume-Chouinard.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Josué Bertolino, Guillaume Chouinard"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Josué-Bertolino.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Josué Bertolino"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Josué-Bertolino(1).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Josué Bertolino"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau,-Josué-Bertolino(2).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau, Josué Bertolino"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Marie-Sophie-Roy,-Michel-Carbonneau.jpg",
        "name": "Photo d'arrière-scène - Marie-Sophie Roy, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Samuel-Thériault.jpg",
        "name": "Photo d'arrière-scène - Samuel Thériault"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau.jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau(1).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau(2).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau(3).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau(4).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau(5).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      },{
        "path": "/images/photos/Photo-d_arrière-scène---Edith-Fournier,-Michel-Carbonneau(6).jpg",
        "name": "Photo d'arrière-scène - Édith Fournier, Michel Carbonneau"
      }, {
        "path": "/images/photos/Photo-d_arrière-scène---Guillaume-Chouinard.jpg",
        "name": "Photo d'arrière-scène - Guillaume Chouinard"
      }, {
        "path": "/images/photos/Samuel-Thériault,-directeur-technique.jpg",
        "name": "Samuel Thériault, directeur technique"
      },{
        "path": "/images/photos/Pascal-Tremblay,-département-artistique-et-technique.jpg",
        "name": "Pascal Tremblay, département artistique et technique"
      },{
        "path": "/images/photos/Marie-Sophie-Roy,-département-artistique-et-technique.jpg",
        "name": "Marie-Sophie Roy, département artistique et technique"
      },{
        "path": "/images/photos/Julien-Boisvert,-preneur-de-son.jpg",
        "name": "Julien Boisvert, preneur de son"
      },{
        "path": "/images/photos/Edith-Fournier.jpg",
        "name": "Édith Fournier"
      },{
        "path": "/images/photos/Michel-Carbonneau.jpg",
        "name": "Michel Carbonneau"
      },{
        "path": "/images/photos/Guillaume-Chouinard---Scénarisation.jpg",
        "name": "Guillaume Chouinard - Scénarisation"
      },{
        "path": "/images/photos/Josué-Bertolino---Réalisation-et-direction-photo.jpg",
        "name": "Josué Bertolino - Réalisation et direction photo"
      },
    ]
  }),
}
</script>
