<template>
  <div class="w-100 my-5 pt-5">
    <transition name="fade-slow" mode="out-in">
      <div v-if="!sent">
        <div class="row my-4 align-items-center">
          <div class="col-12 col-md-6 ">
            <h2 class="h1">Outils d'animation</h2>
          </div>
          <div class="col-12 col-md-6 my-4">
            <router-link :to="{name: 'OutilsAnimationDetails'}" class="btn btn-outline-light">
              <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="mr-3" width="28.91" height="19.27">
              Revenir aux outils d'animation
            </router-link>
          </div>
        </div>
        <form @submit.prevent="askForTools()">
          <div class="row">
            <div class="col-12">
              <p>Pour recevoir les outils d’animation gratuitement, veuillez compléter ce formulaire.</p>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-12 col-sm">
                  <label>Prénom*</label>
                  <input type="text" class="form-control mb-2" placeholder="Denise" v-model="form.firstname" required>
                </div>
                <div class="col-12 col-sm">
                  <label>Nom*</label>
                  <input type="text" class="form-control mb-2" placeholder="Drapeau" v-model="form.lastname" required>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm">
                  <label>Titre d'emploi</label>
                  <input type="text" class="form-control mb-2" placeholder="Titre d'emploi" v-model="form.job">
                </div>
                <div class="col-12 col-sm">
                  <label>Organisation*</label>
                  <input type="text" class="form-control mb-2" placeholder="Organisation" v-model="form.organisation" required>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm">
                  <label>Adresse</label>
                  <input type="text" class="form-control mb-2" placeholder="158 Rue de Bleury" v-model="form.address">
                </div>
                <div class="col-12 col-sm">
                  <label>Ville</label>
                  <input type="text" class="form-control mb-2" placeholder="Montréal" v-model="form.city">
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm">
                  <label>Code postal</label>
                  <input type="text" class="form-control mb-2" placeholder="H2Z 0B8" v-model="form.zip">
                </div>
                <div class="col-12 col-sm">
                  <label>Pays</label>
                  <input type="text" class="form-control mb-2" placeholder="Canada" v-model="form.country">
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm">
                  <label>Téléphone</label>
                  <input type="tel" class="form-control mb-2" placeholder="438-589-5453" v-model="form.phone">
                </div>
                <div class="col-12 col-sm">
                  <label>Courriel*</label>
                  <input type="email" class="form-control mb-2" placeholder="denise.drapeau@gmail.com" v-model="form.email" required>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-2 mt-lg-0">
              <label>Outil(s) d'animation desiré(s)</label>
              <div class="row mb-2">
                <div class="col-12 mb-2" v-for="(outil,index) in outils" :key="index">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-bind:value="outil" :id="'outil_animation_'+index" v-model="form.outils">
                    <label class="form-check-label" :for="'outil_animation_'+index">
                      {{ outil.name }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>Précisions ou commentaires</label>
                  <textarea name="" class="form-control mb-3" rows="5" placeholder="Commentaires" v-model="form.message"></textarea>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <button type="submit" class="btn btn-outline-light">Envoyer le formulaire</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div v-else-if="sent == 'success'" class="text-center">
        <p>Merci pour votre message, nous vous répondrons rapidement</p>
        <router-link :to="{name: 'OutilsAnimationHome'}" class="btn btn-outline-light">
          <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="mr-3" width="28.91" height="19.27">
          Revenir aux outils d'animation
        </router-link>
      </div>
      <div v-else class="text-center">
        <p>Une erreur est survenue, merci de ré-essayer plus tard</p>
        <router-link :to="{name: 'OutilsAnimationHome'}" class="btn btn-outline-light">
          <img src="/images/icons/arrow-left-white.svg" alt="Flèche gauche" class="mr-3" width="28.91" height="19.27">
          Revenir aux outils d'animation
        </router-link>
      </div>
    </transition>

  </div>

</template>

<script>
import axios from "axios";
import qs from 'qs';

export default {
  name: 'OutilsAnimationForm',
	data: () => ({
    outils: [
      {value : "C" , name: "Consignes aux personnes animatrices" },
      {value : "1",  name: "Devenir une personne proche aidante" },
      {value : "2",  name: "S’organiser, demander et obtenir de l’aide" },
      {value : "3",  name: "Vivre un quotidien de plus en plus lourd et apprendre à prendre soin de soi" },
      {value : "4",  name: "Envisager l’hébergement et prendre la décision" },
      {value : "5",  name: "Redéfinir son rôle de personne proche aidante en contexte d’hébergement" },
      {value : "6",  name: "Retomber en amour alors que l’on est toujours proche aidant" },
      {value : "7",  name: "Se préparer à vivre les derniers moments de notre proche" },
      {value : "O" , name: "Outil de la personne participante" },
    ],
    form: {
      firstname: null,
      lastname: null,
      job: null,
      organisation: null,
      address: null,
      city: null,
      zip: null,
      country: null,
      phone: null,
      email: null,
      message: null,
      outils: [],
    },
    sent: false,
  }),
  computed: {
    outilsVal() {
      let tempVal = [];
      Array.from(this.form.outils).forEach(outil => {
        tempVal.push(outil.value)
      })
      tempVal.sort()
      return tempVal
    },
  },
  methods: {
    askForTools() {
      console.log(qs.stringify(this.form))
      axios.post(
          '/mail-outilsAnimation.php',
          qs.stringify(this.form)
        ).then(res => {
          this.sent = res.data;
          if( this.sent == "success" ) {
            this.$gtm.trackEvent({
              'outils_demandes': this.outilsVal.join(' | '),
              'event':'Form'
              });
            this.resetForm()
          }
        });
    },
    resetForm() {
      this.form.firstname= null;
      this.form.lastname= null;
      this.form.job= null;
      this.form.organisation= null;
      this.form.address= null;
      this.form.city= null;
      this.form.zip= null;
      this.form.country= null;
      this.form.phone= null;
      this.form.email= null;
      this.form.message= null;
      this.form.outils= [];
    }
  }
}
</script>
