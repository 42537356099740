<template>
  <div class="menu w-100 h-100 position-fixed">
    <div class="column d-inline-block position-absolute"></div>
    <div class="column d-inline-block position-absolute"></div>
    <div class="column d-inline-block position-absolute"></div>
    <div class="column d-inline-block position-absolute"></div>

    <div class="menu-content w-100 h-100">
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-around">
          <div class="col-auto text-center mt-5">
            <div class="menu-list mb-md-5">
              <router-link :to="{name: 'Chapitres'}" class="text-white h2-5 d-block font-weight-bold mt-5">Série documentaire</router-link>
              <hr class="border-white mb-2 mt-3">
              <router-link :to="{name: 'OutilsAnimationHome'}" class="text-white h2-5 d-block font-weight-bold">Outils d'animation</router-link>
              <hr class="border-white mb-2 mt-3">
              <router-link :to="{name: 'AuDelaDesChiffresHome'}" class="text-white h2-5 d-block font-weight-bold">Au-delà des chiffres</router-link>
              <hr class="border-white mb-2 mt-3">
              <a href="#" @click.prevent class="text-white d-block">
                <span class="h2-5 font-weight-bold" @click.prevent="displaySubMenu = displaySubMenu ? false : true">
                  Arrière-scène
                </span>
                <div class="row justify-content-center mt-4 submenu" :class="{'showSubMenu' : displaySubMenu}">
                  <div class="col-12 col-sm-auto my-1">
                    <router-link :to="{name: 'ArriereSceneArtisans'}" class="big">En tête-à-tête avec les artisans</router-link>
                  </div>
                  <div class="col-12 col-sm-auto my-1">
                    <router-link :to="{name: 'ArriereScenePhotos'}" class="big">Photos</router-link>
                  </div>
                  <div class="col-12 col-sm-auto my-1">
                    <router-link :to="{name: 'ArriereSceneCollaborateurs'}" class="big">Collaborateurs</router-link>
                  </div>
                </div>
              </a>
              <hr class="border-white mb-2 mt-3">
              <router-link :to="{name: 'Balado'}" class="text-white h2-5 d-block font-weight-bold">Le balado</router-link>
              <router-link :to="{name: 'BaladoTestimonial'}" class="text-white h2-5 d-block mt-4">
                <button class="h2-5 btn btn-outline-light font-weight-bold">
                  <span>Témoignez</span>
                </button>
              </router-link>
            </div>
            <img src="/images/Logo_SiteWeb.svg" alt="Logo Appui Nav" class="mt-4" width="116" height="52">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data: () => ({
    displaySubMenu: false,
  }),
  methods: {
  },
  updated() {
    // this.displaySubMenu = false;
    // this.displaySubMenu = false;
    // console.log('menu-updated')
  }
}
</script>
