const videosArtisans = [{
  "edith" : {
    "id": 1,
    "links": {
      "prev": "julie",
      "next": "michel",
    },
    "titre": "Édith Fournier",
    "meta": {
      "title": "Entrevue avec Édith Fournier - Au-delà des Mots",
      "description": "L'autrice et l'interprète d'Au-delà des mots, Édith Fournier, retrace son parcours de proche aidante et livre ses conseils dans une entrevue intimiste."
    },
    "image_poster": "/images/videocovers/Edith.jpg",
    "name": "Rencontre avec Édith Fournier, autrice et interprète",
    "video": "/videos/Edith_Entrevue.mp4",
  },
  "michel" : {
    "id": 2,
    "links": {
      "prev": "edith",
      "next": "josue",
    },
    "titre": "Michel Carbonneau",
    "meta": {
      "title": "Entrevue avec Michel Carbonneau - Au-delà des Mots",
      "description": "Michel Carbonneau, auteur et lnterprète, profite de cette entrevue pour se confier sur la proche aidance et aborder la production d'Au-delà des mots."
    },
    "image_poster": "/images/videocovers/Michel.jpg",
    "name": "Rencontre avec Michel Carbonneau, auteur et interprète",
    "video": "/videos/Michel_Entrevue.mp4",
  },
  "josue" : {
    "id": 3,
    "links": {
      "prev": "michel",
      "next": "guillaume",
    },
    "titre": "Josué Bertolino",
    "meta": {
      "title": "Entrevue avec Josué Bertolino - Au-delà des Mots",
      "description": "Dans cette entrevue, Josué Bertolino, le réalisateur d'Au-delà des mots, détaille son approche artistique et évoque la proche aidance."
    },
    "image_poster": "/images/videocovers/Josue.jpg",
    "name": "Rencontre avec Josué Bertolino, réalisateur",
    "video": "/videos/Josue_Entrevue.mp4",
  },
  "guillaume" : {
    "id": 4,
    "links": {
      "prev": "josue",
      "next": "julie",
    },
    "titre": "Guillaume Chouinard",
    "meta": {
      "title": "Entrevue avec Guillaume Chouinard - Au-delà des Mots",
      "description": "Le scénariste et metteur en scène, Guillaume Chouinard, raconte en entrevue les liens familiaux d'Au-delà des mots et revient sur l'écriture du scénario."
    },
    "image_poster": "/images/videocovers/Guillaume.jpg",
    "name": "Rencontre avec Guillaume Chouinard, scénariste et metteur en scène",
    "video": "/videos/Guillaume_Entrevue.mp4",
  },
  "julie" : {
    "id": 5,
    "links": {
      "prev": "guillaume",
      "next": "edith",
    },
    "titre": "Julie Bickerstaff C.",
    "meta": {
      "title": "Entrevue avec Julie Bickerstaff.C - Au-delà des Mots",
      "description": "En entrevue, Julie Bickerstaff C., chargée du projet, présente le rôle de l'Appui dans la production d'Au-delà des Mots."
    },
    "image_poster": "/images/videocovers/Julie.jpg",
    "name": "Rencontre avec Julie Bickerstaff C., gestionnaire du projet chez l'Appui pour les proches aidants",
    "video": "/videos/Julie_Entrevue.mp4",
  },
}]

export default videosArtisans;
