<template>
  <div class="w-100 my-15 align-self-start">
    <div class="shadow"></div>
    <div class="container content z-top">

      <!-- // INTRO -->
      <div class="row">
        <div class="col-12 col-md-11 col-lg-10 col-xl-8">
          <h1 class="mt-4 mb-4 mb-md-5">Témoignez de votre expérience</h1>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="sent != 'success'">
          <div class="row">
            <div class="col-12 col-md-11 col-lg-10 col-xl-8">
              <p>Si, vous aussi, vous prenez soin d’une personne (enfant, parent, ami ou même voisin), votre parcours nous intéresse. Partagez-nous votre histoire de proche aidance en audio ou par écrit.</p>
            </div>
          </div>
          <!-- // LAISSER UN TEMOIGNAGE AUDIO -->
          <div class="bg-white my-5 px-5 py-4">
            <div class="row py-4">
              <div class="col-12 text-center text-dark">
                <transition name="fade-slow" mode="out-in">
                  <div v-if="!audioFile && !is_recording">
                    <h2 class="h3 font-weight-bold">Enregistrez votre témoignage</h2>
                    <button type="button" class="btn btn-outline-dark p-0 rounded-circle mt-3" @click="record">
                      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
                        <g transform="translate(-649 -502)">
                          <g transform="translate(642 500.598)">
                            <path fill="#251f27" d="M37,22.967v8.609a6.217,6.217,0,1,0,12.435,0V22.967a6.217,6.217,0,0,0-12.435,0Z"
                              transform="translate(-5.217)" />
                            <path fill="#251f27"
                              d="M49,45.665V44.67H46.13v.995a8.13,8.13,0,1,1-16.261,0V44.67H27v.995a11,11,0,0,0,9.565,10.9v3.683H33.217v2.87h9.565v-2.87H39.435V56.569A11,11,0,0,0,49,45.665Z"
                              transform="translate(0 -14.567)" />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div v-else-if=" is_recording">
                    <h2 class="h3 font-weight-bold">{{ ("0" + Math.floor(record_length % (3600*24) / 3600)).slice(-2) }} : {{ ("0" + Math.floor(record_length % 3600 / 60)).slice(-2) }} : {{ ("0" + Math.floor(record_length % 60)).slice(-2) }}</h2>
                    <div class="row justify-content-center">
                      <div class="col-auto">
                        <button type="button" class="btn btn-outline-dark p-0 rounded-circle mt-3" @click="stopRecording">
                          <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="61" height="61"
                            viewBox="0 0 61 61" style="enable-background:new 0 0 61 61;" xml:space="preserve">
                            <rect x="21" y="21" width="19" height="19"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!form.audioUrl && audioFile && !is_uploading">
                    <div class="px-5">
                      <audioPlayer :src="audioFile"/>
                    </div>
                    <div class="row justify-content-center mt-4">
                      <div class="col-auto">
                        <button type="submit" class="btn btn-outline-dark" @click="uploadAudio">
                          Valider ce témoignage
                          <img src="/images/icons/arrow-right-dark.svg" alt="Flèche droite" class="ml-3" width="28" height="19">
                        </button>
                      </div>
                      <div class="col-auto">
                        <button type="submit" class="btn btn-outline-dark" @click="deleteAudio">
                          Recommencer ce témoignage
                          <img src="/images/icons/arrow-right-dark.svg" alt="Flèche droite" class="ml-3" width="28" height="19">
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="is_uploading && !form.audioUrl">
                    <div class="spinner-border mb-3" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <p class="font-serif mb-0">Envoi de votre témoignage en cours...<br>Cela peut prendre quelques minutes.</p>
                  </div>
                  <div v-else-if="form.audioUrl">
                    <img src="/images/icons/checked.svg" width="28" height="28" alt="checked" class="mb-3">
                    <p class="font-serif mb-0">Votre témoignage est bien enregistré,<br>vous pouvez à present remplir vos informations de contact pour le soumettre</p>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <!-- // LAISSER UN TEMOIGNAGE TEXTE -->
          <div class="row">
            <div class="col-12">
              <h2 class="h4 text-white mb-4">Ou écrivez votre témoignage</h2>
              <form @submit.prevent="sendTestimonial()">
                <div class="row">
                  <div class="col-12">
                    <textarea name="" class="form-control mb-3" rows="5" placeholder="Ecrire ici votre témoignage" v-model="form.message"></textarea>
                    <p class="my-4">Veuillez noter qu'aucune information personnelle ne sera partagée avec nos partenaires ou sans votre consentement.</p>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Prénom</label>
                    <input type="text" class="form-control mb-2" placeholder="Denise" v-model="form.firstname" required>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Nom</label>
                    <input type="text" class="form-control mb-2" placeholder="Drapeau" v-model="form.lastname" required>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Téléphone</label>
                    <input type="tel" class="form-control mb-2" placeholder="(438) 589-5453" v-model="form.phone" required>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Courriel</label>
                    <input type="email" class="form-control mb-2" placeholder="denise.drapeau@gmail.com" v-model="form.email" required>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Région où vous vivez</label>
                    <select class="form-control mb-2" v-model="form.region" required>
                      <option value="" disabled>Sélectionnez une région</option>
                      <option value="Abitibi-Témiscamingue">Abitibi-Témiscamingue</option>
                      <option value="Bas-Saint-Laurent">Bas-Saint-Laurent</option>
                      <option value="Capitale-Nationale">Capitale-Nationale</option>
                      <option value="Centre-du-Québec">Centre-du-Québec</option>
                      <option value="Chaudière-Appalaches">Chaudière-Appalaches</option>
                      <option value="Côte-Nord">Côte-Nord</option>
                      <option value="Estrie">Estrie</option>
                      <option value="Gaspésie-Îles-de-la-Madeleine">Gaspésie-Îles-de-la-Madeleine</option>
                      <option value="Jamésie">Jamésie</option>
                      <option value="Lanaudière">Lanaudière</option>
                      <option value="Laurentides">Laurentides</option>
                      <option value="Laval">Laval</option>
                      <option value="Mauricie">Mauricie</option>
                      <option value="Montérégie">Montérégie</option>
                      <option value="Montréal">Montréal</option>
                      <option value="Outaouais">Outaouais</option>
                      <option value="Saguenay-Lac-Saint-Jean">Saguenay-Lac-Saint-Jean</option>
                    </select>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Maladie ou situation du proche</label>
                    <input type="text" class="form-control mb-2" placeholder="Alzheimer" v-model="form.situation" required>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4 mb-2">
                    <label>Lien avec le proche</label>
                    <input type="text" class="form-control mb-2" placeholder="Frère" v-model="form.relationship" required>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="row mb-2">
                      <div class="col-12 mb-2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-bind:value="form.diffusion" id="diffusion" v-model="form.diffusion">
                          <label class="form-check-label" for="diffusion">
                            J'accepte que mon témoignage soit diffusé sur les plateformes numériques de l'Appui pour les aidants
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-12 mb-2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-bind:value="form.anonyme" id="anonyme" v-model="form.anonyme">
                          <label class="form-check-label" for="anonyme">
                            Je souhaite demeurer anonyme
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-12 mb-2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-bind:value="form.contact_me" id="contact_me" v-model="form.contact_me">
                          <label class="form-check-label" for="contact_me">
                            J'accepte d'être contacter dans le cadre de tout autre projet initié par l'Appui pour les proches aidants
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <button type="submit" class="btn btn-outline-light" v-if="!sending">
                      Envoyer votre témoignage
                      <img src="/images/icons/arrow-right-white.svg" alt="Flèche droite" class="ml-3" width="28" height="19">
                    </button>
                    <button type="submit" class="btn btn-outline-light" v-else disabled>
                      Envoye en cours...
                    </button>
                  </div>



                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-else>
          <h3 class="font-weight-bold mb-4">Merci énormément pour votre témoignage!</h3>
          <p>C'est avec grand intérêt nous allons vous lire (ou vous écouter).</p>
          <div class="row mt-5">
            <div class="col-12 col-md-11 col-lg-10 col-xl-8">
              <div class="border border-white p-4 bg-dark">
                <div class="row align-items-center">
                  <div class="col-6">
                    <p><strong>Si vous ressentez le besoin de parler ou d'être orienter dans votre role de personne proche aidante, n'hésitez à contacter Info-aidant.</strong></p>
                    <img src="/images/Logo-info-aidant.svg" alt="Logo info aidants" class="mb-4 img-fluid">
                  </div>
                  <div class="col-6">
                    <a href="tel:+18558527784" class="btn btn-block d-flex justify-content-center btn-light align-items-center font-sans-serif py-3">
                      <img src="/images/icons/phone.svg" alt="Logo téléphone su fond orange" class="mr-2" width="29" height="29">
                      <span class="small font-weight-bold">Appelez-nous au 1 855 852-7784</span>
                    </a>
                    <a href="mailto:info-aidant@lappui.org" class="btn btn-block d-flex justify-content-center btn-light align-items-center font-sans-serif py-3">
                      <img src="/images/icons/mail.svg" alt="Logo téléphone su fond orange" class="mr-2" width="29" height="29">
                      <span class="small font-weight-bold">info-aidant@lappui.org</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import AudioManager from '../../node_modules/edith-michel-audio/audio.js';
import AudioPlayer from '../components/AudioPlayer.vue';
import axios from "axios";
import qs from 'qs';

export default {
  name: 'BaladoTestimonial',
  data: () => ({
    form: {
      message: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      situation: "",
      region: "",
      relationship: "",
      diffusion: "",
      anonyme: "",
      contact_me: "",
      audioUrl: "",
    },
    is_recording: false,
    is_uploading: false,
    audioManager: null,
    record_length: 0,
    interval: null,
    audioFile: null,
    audioIndex: null,
    audioPlayer: null,
    sent: null,
    sending: false,
  }),
  components: {
    AudioPlayer,
  },
  methods: {
    record() {
      if (this.audioManager.canRecord()) {
        // hide(event.target)
        try {
          this.audioManager.record();
        } catch (error) {
          alert("Vous êtes deja en enregistrement.");
        }
      }
    },
    startRecordTiming() {
      this.is_recording = true;
      this.record_length = 0;
      this.interval = setInterval(() => {
        this.record_length++;
      }, 1000);
    },
    stopRecording() {
      this.audioManager.stopRecording();
      this.is_recording = false;
      clearInterval(this.interval);
      this.record_length = 0;
    },
    uploadAudio() {
      this.is_uploading = true;
      this.audioManager.upload();
    },
    deleteAudio() {
      this.audioFile = null
    },
    sendTestimonial() {
      console.log(qs.stringify(this.form))
      this.sending = true;
      axios.post(
          '/mail-baladoTestimonial.php',
          qs.stringify(this.form)
        ).then(res => {
          this.sent = res.data;
          this.sending = false;
          if( this.sent == "success" ) {
            this.resetForm()
          }
        }).catch(error => {
          console.log(error)
          this.sending = false;
        });
    },
    resetForm() {
      for (const key of Object.entries(this.form)) {
        this.form[key] = "";
      }
    }
  },
  mounted() {
    const serverUploadUrl = '/upload.php';
    try {
      this.audioManager = new AudioManager(serverUploadUrl);
    } catch (error) {
      console.log(error);
      return;
    }

    this.audioManager.on(this.audioManager.EVENT_STARTED_RECORDING, () => {
      this.startRecordTiming();
    });

    this.audioManager.on(this.audioManager.EVENT_SAVED_AUDIO, (data) => {
      this.audioFile = URL.createObjectURL(data.audio)
      this.audioIndex = data.index
    });

    this.audioManager.on(this.audioManager.EVENT_UPLOADED_AUDIO, (data) => {
      this.form.audioUrl = data.url;
    });

  },
}
</script>
