<template>
  <div>
    <div class="row align-items-center my-5 pt-3 pt-lg-5">
      <div class="col-12 col-md-10 col-lg-7 col-xl-6">
        <h2 class="h1 mt-4 mb-5 mb-md-5">Téléchargement des outils d'animation</h2>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="row align-items-center mb-4" v-for="(outil,index) in outils.slice(0,Math.ceil(outils.length/2))" :key="index">
              <div class="col-auto">
                <a :href="outil.file" target="_blank" download class="btn btn-outline-light p-0 border-0">
                  <img src="/images/icons/download.svg" alt="download svg icon">
                </a>
              </div>
              <div class="col">
                <p class="font-serif mb-0" v-if="outil.type"><em>— {{ outil.type }}</em></p>
                <a :href="outil.file" @click="gtm_push(outil.value)" target="_blank" download class="big font-serif font-weight-bold mb-0">{{ outil.name }}</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="row align-items-center mb-4" v-for="(outil,index) in outils.slice(Math.ceil(outils.length/2),outils.length)" :key="index">
              <div class="col-auto">
                <a :href="outil.file" target="_blank" download class="btn btn-outline-light p-0 border-0">
                  <img src="/images/icons/download.svg" alt="download svg icon">
                </a>
              </div>
              <div class="col">
                <p class="font-serif mb-0" v-if="outil.type"><em>— {{ outil.type }}</em></p>
                <a :href="outil.file" @click="gtm_push(outil.value)" target="_blank" download class="big font-serif font-weight-bold mb-0">{{ outil.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutilsAnimationDownload',
  data: () => ({
    outils : [
      {
        name: "Consignes aux personnes animatrices",
        value: "C",
        file: "/outils_animation/Appui_Consignes_aux_personnes_animatrices.pdf",
      }, {
        type: "Outil d'animation 1",
        name : "Devenir une personne proche aidante",
        value: "1",
        file : "/outils_animation/Appui_Fiche_animation_01.pdf",
      }, {
        type: "Outil d'animation 2",
        name: "S’organiser, demander et obtenir de l’aide",
        value: "2",
        file: "/outils_animation/Appui_Fiche_animation_02.pdf",
      }, {
        type: "Outil d'animation 3",
        name: "Vivre un quotidien de plus en plus lourd et apprendre à prendre soin de soi",
        value: "3",
        file: "/outils_animation/Appui_Fiche_animation_03.pdf",
      }, {
        type: "Outil d'animation 4",
        name: "Envisager l’hébergement et prendre la décision",
        value: "4",
        file: "/outils_animation/Appui_Fiche_animation_04.pdf",
      }, {
        type: "Outil d'animation 5",
        name: "Redéfinir son rôle de personne proche aidante en contexte d’hébergement",
        value: "5",
        file: "/outils_animation/Appui_Fiche_animation_05.pdf",
      }, {
        type: "Outil d'animation 6",
        name: "Retomber en amour alors que l’on est toujours proche aidant",
        value: "6",
        file: "/outils_animation/Appui_Fiche_animation_06.pdf",
      }, {
        type: "Outil d'animation 7",
        name: "Se préparer à vivre les derniers moments de notre proche",
        value: "7",
        file: "/outils_animation/Appui_Fiche_animation_07.pdf",
      }, {
        name: "Outil de la personne participante",
        value: "O",
        file: "/outils_animation/Appui_Fiche_du_participant.pdf",
      }
    ]
  }),
  methods: {
    gtm_push(outil) {
      this.$gtm.trackEvent({
        'outils_telecharges': outil,
        'event':'Download'
      });
    }
  }
}
</script>
