<template>
  <div class="w-100 h-100 position-fixed">
    <div class="shadow"></div>
    <div class="content w-100 py-5 mt-5">

      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="container">
          <div class="main-swiper-wrapper w-100 position-relative pt-4">

            <swiper
              ref="imagesSwiper"
              :slides-per-view="1"
              :speed="800"
              navigation
              @init="initSwiper"
              @slideChange="onSlideChange"
              @swiper="setFirstSwiper"
              :controller="{ control: secondSwiper }"
              :loop="true"
              :loopAdditionalSlides="5"
              :autoDestroy="true"
              class="imagesSwiper"
            >
              <swiper-slide v-for="(chapitre,index) in chapitres" :key="chapitre.id">
                <router-link :to="{name:'SingleChapitre',params: { id: index }}" class="d-block d-sm-none text-left mb-3">
                  <p class="h4">
                    <em>— Chapitre {{ chapitre.id }}</em>
                  </p>
                  <p class="h2 font-weight-bold">
                    {{ chapitre.titre }}
                  </p>
                </router-link>
                <router-link :to="{name:'SingleChapitre',params: { id: index }}">
                  <div class="pl-sm-5 pr-5 mx-auto">
                    <div class="img bg-cover mx-auto bg-dark" :style="'background-image:url('+chapitre.image_poster+')'"></div>
                  </div>
                </router-link>
              </swiper-slide>
            </swiper>

            <div class="custom-pagination mx-auto text-right mt-3 big pr-5 pr-lg-0" v-show="totSlides">
              {{ ("0" + curSlide).slice(-2) }} — {{ ("0" + totSlides).slice(-2) }}
            </div>

            <swiper
              ref="chapitresSwiper"
              :slides-per-view="1"
              :space-between="0"
              direction="vertical"
              :speed="800"
              class="position-absolute chapitre-swiper d-none d-sm-block"
              @swiper="setSecondSwiper"
              :controller="{ control: firstSwiper }"
              :slideToClickedSlide="true"
              :loop="true"
              :loopAdditionalSlides="5"
            >
              <swiper-slide v-for="(chapitre,index) in chapitres" :key="chapitre.id" class="text-left position-relative">
                <router-link :to="{name:'SingleChapitre',params: { id: index }}" class="position-absolute w-100 h-100 ghost-link"></router-link>
                <p class="h4">
                  <em>— Chapitre {{ chapitre.id }}</em>
                </p>
                <p class="h2 font-weight-bold">
                  {{ chapitre.titre }} <img src="/images/icons/arrow-right-white.svg" class="ml-2">
                </p>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Controller } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Controller]);

export default {
  name: 'Chapitres',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    curSlide : false,
    totSlides : false,
    firstSwiper: null,
    secondSwiper: null,
  }),
  mounted() {
    console.log('CHAPITRES READY')
  },
  methods: {
    initSwiper(swiper) {
      this.curSlide = swiper.realIndex + 1;
      this.totSlides = document.querySelectorAll('.chapitre-swiper .swiper-slide:not(.swiper-slide-duplicate)').length;
    },
    onSlideChange(swiper) {
      this.curSlide = swiper.realIndex + 1;
    },
    setFirstSwiper(swiper) {
      this.firstSwiper = swiper;
    },
    setSecondSwiper(swiper) {
      this.secondSwiper = swiper;
    },
  },
};
</script>
