<template>
  <div>
    <Header/>
    <div class="container">
      <transition name="menu-animation" :duration="1000">
        <Menu v-show="displayMenu"/>
      </transition>
    </div>

    <main>
      <router-view v-slot="slotProps">
        <transition :name="transitionName" :mode="transitionMode">
          <component :is="slotProps.Component" :key="$route.params.chapitre"></component>
        </transition>
      </router-view>

    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'

export default {
  name: 'App',
  components: {
    Header,
    Menu
  },
  data: () => ({
    displayMenu: false,
    transitionName : "fade-slow",
    transitionMode: "out-in"
  }),
  methods: {
    openMenu(val) {
      this.displayMenu = val;
    }
  },
  watch: {
    '$route' () {
      this.displayMenu = false;
      this.transitionMode = "out-in";
      this.transitionName = "fade-slow";
    }
  }
}
</script>


<style lang="scss">
  @import "./assets/scss/_variables.scss";
  @import "../node_modules/bootstrap/scss/bootstrap.scss";

  @import 'swiper/components/navigation/navigation.scss';
  @import 'swiper/swiper.scss';
  @import 'swiper/components/effect-fade/effect-fade.scss';

  @import "./assets/scss/_transitions.scss";
  @import "./assets/scss/_global.scss";
  @import "./assets/scss/_menu.scss";
  @import "./assets/scss/_form.scss";
  @import "./assets/scss/_outils-animation.scss";
  @import "./assets/scss/_bandeaux.scss";
  @import "./assets/scss/_slider.scss";
  @import "./assets/scss/_videoplayer.scss";
  @import "./assets/scss/_balado.scss";
</style>
